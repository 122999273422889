import React, { useState } from "react";

function Recordcbr() {
  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };
  return (
    <>
      <form onSubmit={handleSubmit}   className="xs:mt-[6px] w-[50%] mx-auto lg:mt-[3rem]">
        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field1"
          >
            Thankyou to :
          </label>
          <input
                className="mont-serif  appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="field1"
            id="field1"
            value={formData.field1}
            onChange={handleChange}
          />
        </div>

        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field2"
          >
            Bussiness Type :
          </label>

          <div   className="flex flex-row justify-center items-center mx-auto">
            <button
                  className="border border-green-900  w-[19rem] p-[9px]  mont-serif fade-in-right"
              type="submit"
            >
              New
            </button>
            <button
                  className="border bg-green-600  text-white w-[19rem] p-[9px]  mont-serif fade-in-right"
              type="submit"
            >
              Repeat
            </button>
          </div>
        </div>

        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field3"
          >
            Meeting Location:
          </label>
          <div   className="flex flex-row justify-center items-center mx-auto">
            <button
                  className="border border-green-900  w-[19rem] p-[9px]  mont-serif fade-in-right"
              type="submit"
            >
              New
            </button>
            <button
                  className="border bg-green-600  text-white w-[19rem] p-[9px]  mont-serif fade-in-right"
              type="submit"
            >
              Outside
            </button>
            <button
                  className="border  text-[#005125]  border-green-900  w-[19rem] p-[9px]  mont-serif fade-in-right"
              type="submit"
            >
              Repeat
            </button>
          </div>
        </div>

        <div   className="mb-4">
          <label
              className="block text-[#005125] font-bold mb-2 mont-serif"
            htmlFor="field4"
          >
          Comments
          </label>
          <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            type="text"
            name="field4"
            id="field4"
            value={formData.field4}
            onChange={handleChange}
          />
        </div>

    
        <div   className="flex flex-col gap-3 justify-center items-center mt-[3rem]">
          <button
                className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left"
            type="submit"
          >
            Confirm
          </button>
       
        </div>
      </form>
    </>
  );
}

export default Recordcbr;
