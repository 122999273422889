import React from "react";

import img from "../AdminImage/building.png";
import { Link } from "react-router-dom";

function EventHistoryTable() {
  return (
    <>
      <div className="lg:container lg:mx-auto">
        <div className="flex flex-col text-center w-full mb-20"></div>
        <div className=" lg:w-[90%] mx-auto overflow-auto">
          <table className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl">company</th>
                <th className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl">name</th>
                <th className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl">
                 description
                </th>
                <th className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
               participants
                </th>
            
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  <div className="image-grid lg:w-[50px]">
                    <img src={img} className="h-[39px] mx-auto" alt="" />
                  </div>
                </td>

                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                 
              </tr>
              <tr>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  <div className="image-grid lg:w-[50px]">
                    <img src={img} className="h-[39px] mx-auto" alt="" />
                  </div>
                </td>

                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                 
              </tr>
              <tr>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  <div className="image-grid lg:w-[50px]">
                    <img src={img} className="h-[39px] mx-auto" alt="" />
                  </div>
                </td>

                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                 
              </tr>
              <tr>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  <div className="image-grid lg:w-[50px]">
                    <img src={img} className="h-[39px] mx-auto" alt="" />
                  </div>
                </td>

                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                 
              </tr>
              <tr>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  <div className="image-grid lg:w-[50px]">
                    <img src={img} className="h-[39px] mx-auto" alt="" />
                  </div>
                </td>

                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                 
              </tr>
            </tbody>
          </table>
       
        </div>
      </div>
      <div className="button mx-auto flex justify-center  mt-16">
          
          <Link to="/admin" >  <button className="lg:w-[10rem] btn-dc border border-green-700 mont-serif text-black mx-auto ">
              Go Back
            </button></Link>
          
          </div>
    </>
  );
}

export default EventHistoryTable;
