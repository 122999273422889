import React, { useState } from "react";
import profilebg from "./graphimage/profilevector.png";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import edit from "./graphimage/edit.png";
import { RxCross1 } from "react-icons/rx";
import logout from "./graphimage/Cancel (1).png";
import {  useNavigate } from "react-router-dom";

export default function Userprofile() {
  const [input1Value, setInput1Value] = useState("");
  const [input2Value, setInput2Value] = useState("");
  const [input3Value, setInput3Value] = useState("");
  const [checkbox1Checked, setCheckbox1Checked] = useState(false);
  const [checkbox2Checked, setCheckbox2Checked] = useState(false);
  const [checkbox3Checked, setCheckbox3Checked] = useState(false);

  const handleInput1Change = (event) => {
    setInput1Value(event.target.value);
  };

  const handleInput2Change = (event) => {
    setInput2Value(event.target.value);
  };

  const handleInput3Change = (event) => {
    setInput3Value(event.target.value);
  };

  const handleCheckbox1Change = (event) => {
    setCheckbox1Checked(event.target.checked);
  };

  const handleCheckbox2Change = (event) => {
    setCheckbox2Checked(event.target.checked);
  };

  const handleCheckbox3Change = (event) => {
    setCheckbox3Checked(event.target.checked);
  };
  
  const navigate = useNavigate();
  const handlelogin = () => {
    navigate(`/login`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    console.log("Form submitted!");
    console.log("Input 1:", input1Value);
    console.log("Input 2:", input2Value);
    console.log("Input 3:", input3Value);
    console.log("Checkbox 1:", checkbox1Checked);
    console.log("Checkbox 2:", checkbox2Checked);
    console.log("Checkbox 3:", checkbox3Checked);
  };
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  return (
    <>
      {isModalOpen && (
        <div className="xs:hidden lg:block fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

                <div className="mont-serif  text-[#005125]  leading-relaxed mt-3 text-ceneter ">
                  Doing so will permenantly remove all your
                  <br />
                  <span className="text-center">
                    {" "}
                    account history and data.
                  </span>
                </div>
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogin} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="lg:h-full w-96 sm:max-w-sm md:max-w-sm lg:max-w-sm xl:max-w-sm sm:mx-auto md:mx-auto shadow-md lg:mx-auto xl:mx-auto mt-16 bg-white  rounded-md text-gray-900  fade-in-profile ">
        <div className="rounded-t-lg h-[8.5rem] overflow-hidden bg-profile">
          <img
            className="object-cover w-full h-full opacity-[0.2]"
            src={profilebg}
            alt="Mountain"
          />
        </div>
        <div className="mx-auto w-32 h-32 relative -mt-16 border-4 border-white rounded-full overflow-hidden">
          <img
            className="object-cover object-center h-32"
            src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=400&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
            alt="img-loading"
          />
        </div>
        <span>
          <img
            src={edit}
            className="inline float-right relative lg:right-[128px] lg:bottom-[30px] opacity-[0.8] h-[27px] w-auto"
            alt=""
          />
        </span>
        <div className="text-center mt-2">
          <h2 className="font-semibold mont-serif text-2xl text-[#005125]">
            USER NAME
          </h2>
        </div>
        {/* form fields  */}
        <div className="container mx-auto p-4 mt-2">
          <form onSubmit={handleSubmit}>
            <div className="mb-4 flex flex-row items-center gap-[1rem]">
              <label htmlFor="input1" className="block mb-2 mont-serif">
                Email address
              </label>
              <input
                type="text"
                id="input1"
                // dynamic value
                // value={input1Value}

                // static value for frotend view
                value="developer@aipixel.tech"
                onChange={handleInput1Change}
                className="relative bottom-[2px]  w-auto mont-serif appearance-none focus:border-none   py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              />
              {/* <input  type="text" name="field1" id="field1" value=""> */}
            </div>

            <div className="mb-4  flex flex-row items-center gap-[3rem]  relative lg:[5px]">
              <label htmlFor="input2" className="block mb-2 mont-serif">
                Password
              </label>
              <input
                type="password"
                id="input2"
                // value={input2Value}
                // static value
                value="*******************"
                onChange={handleInput2Change}
                className="text-2xl relative bottom-[14px] w-[13rem] mont-serif appearance-none focus:border-b  focus:border-indig-500 py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              />
              <span className="relative right-[5rem] bottom-[19px] text-[#6d9056]">
                <BsFillPencilFill />
              </span>
            </div>

            <p className="mont-serif p-[12px] mt-[1.5rem] mb-[1rem]">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
              quas repellendus fuga dolorum delectus nam totam minima incidunt
              modi reprehenderit.\
            </p>

            <div className="relative left-[23px]">
              <div className="mb-4">
                <label
                  htmlFor="checkbox1"
                  className="inline-flex items-center mont-serif mont-serif gap-16"
                >
                  <input
                    type="checkbox"
                    id="checkbox1"
                    checked={checkbox1Checked}
                    onChange={handleCheckbox1Change}
                    className="mr-2 text-[#000000]"
                  />
                  Receive notifications
                </label>
              </div>
              {/*  */}
              <div className="mb-4">
                <label
                  htmlFor="checkbox1"
                  className="inline-flex items-center mont-serif mont-serif gap-16"
                >
                  <input
                    type="checkbox"
                    id="checkbox2"
                    checked={checkbox2Checked}
                    onChange={handleCheckbox2Change}
                    className="mr-2 text-[#000000]"
                  />
                  Receive Push notifications
                </label>
              </div>
              {/*  */}
              <div className="mb-4">
                <label
                  htmlFor="checkbox1"
                  className="inline-flex items-center mont-serif gap-16"
                >
                  <input
                    type="checkbox"
                    id="checkbox3"
                    checked={checkbox3Checked}
                    onChange={handleCheckbox3Change}
                    className="mr-2 text-[#000000]"
                  />
                  Show floating icon
                </label>
              </div>

              <div className="mb-4 mt-3 relative rigt-[13px] flex  gap-[14px]">
                <label
                  htmlFor="checkbox1"
                  className="inline-flex items-center mont-serif"
                >
                  Language
                  <p className="text-[#000] mont-serif ml-8">English</p>
                  <span className="relative right-[-6rem] bottom-[3px] text-[#6d9056]">
                    <BsFillPencilFill />
                  </span>
                </label>
              </div>
            </div>
          </form>
        </div>
        {/* fields end  */}
        <div
          onClick={toggleModal}
          className="p-4 border-t mx-8 mt-2 flex flex-row-reverse	 justify-center items-center gap-2 cursor-pointer"
        >
          <p onClick={toggleModal} className="text-center text-red-600 cursor-pointer">
            Delete Account{" "}
          </p>
          <span onClick={toggleModal} className="text-red-600 cursor-pointer">
            <AiFillDelete />
          </span>
        </div>
      </div>
    </>
  );
}
