import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
//
import { AiFillHome } from "react-icons/ai";
import {
  BsPeopleFill,
  BsFillShieldLockFill,
  BsFillChatFill,
} from "react-icons/bs";
import { HiLink } from "react-icons/hi";

import { HiRocketLaunch } from "react-icons/hi2";
import { FaCalculator } from "react-icons/fa";
import { RxExit } from "react-icons/rx";
import icona from "../assets/welcomepage/icon-d.png";

//
export default function SideMobile() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <nav className="bg-card">
        <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
              <button
                onClick={toggleNavbar}
                className="inline-flex items-center justify-center p-2  text-white focus:outline-none "
              >
                {isOpen ? (
                  <FiX className="h-6 w-6 focus:outline-0 hover:border-none text-white" />
                ) : (
                  <FiMenu className="h-6 w-6 focus:outline-0 hover:border-none text-white" />
                )}
              </button>
            </div>
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <Link
                  to="/"
                  className="text-white text-xl font-bold font-all mont-serif"
                >
                  DTEC RABBIT
                </Link>
              </div>
              <div className="hidden sm:block sm:ml-6">
                <div className="flex space-x-4"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`sm:hidden transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "h-[23rem]" : "h-0"
          }`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1">
            <Link
              to="/"
              className="text-white hover:text-white lg:block px-3 py-2  text-base border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3 "
            >
              Home <AiFillHome className="text-white" />
            </Link>
            <Link
              to="/aboutus"
              className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base l:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              About
                    <BsPeopleFill   className="text-white relative right-[1px]" />

            </Link>
            <Link
              to="/members"
              className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif  flex items-center justify-center flex-row-reverse	font-bold  gap-3 "
            >
             <span className="relative left-[6px]"> Members </span>   <img src={icona}   className="h-[23px] relative left-[9px]" alt="" />
            </Link>
            <Link
              to="/league"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Leagues      <HiRocketLaunch />
            </Link>
            <Link
              to="/event"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Events   <FaCalculator className="text-white" />
            </Link>
            <Link
              to="/chats"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Chats      <BsFillChatFill  className="text-white"/>
            </Link>
            <Link
              to="/privacy"
              className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center	font-bold  gap-3"
            >
                  <BsFillShieldLockFill  className="text-white"/>
              Privacy
            </Link>
            <Link
              to="/referal"
              className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base  border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
            >
              Referal    <HiLink  className="text-white"/>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
