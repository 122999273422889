import React from "react";

function IntrestC() {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-y inline-flex flex-col ml-[2rem]">
        <h3 className="text-left text-black mont-serif text-2xl inline mt-[16px]">
          Q: Lorem ipsum dolor sit.ipsum dolor sit.
        </h3>
      </div>

      <div className="flex lg:flex-row xs:ml-[30px]  xs:relative xs:top-[-21px] xs:flex-col  justify-between mt-[2.5rem] lg:ml-[4rem] gap-[1rem] w-[max-content] fade-in-right ">
        <div className="f-5-a xs:flex  lg:flex lg:flex-row  lg:gap-[1rem]">
          {" "}
          <div className="filled-true lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl text-white relative lg:top-[14px]">
              {" "}
              1
            </span>
          </div>
          <div className="digit-box lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl relative lg:top-[14px]">
              {" "}
              2
            </span>
          </div>
          <div className="digit-box lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl relative lg:top-[14px]">
              {" "}
              3
            </span>
          </div>
          <div className=" filled-true relative lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-white text-xl relative lg:top-[14px]">
              4
            </span>
          </div>
          <div className=" filled-true relative    lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl text-white relative  lg:top-[14px] ">
              {" "}
              5
            </span>
          </div>
        </div>

        <div className="f-5-b xs:flex lg:flex lg:flex-row lg:gap-[2rem]">
          {" "}
          <div className="filled-true lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl  text-white relative lg:top-[14px]  ">
              6
            </span>
          </div>
          <div className="digit-box lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl relative  lg:top-[14px] ">
              7
            </span>
          </div>
          <div className="filled-true lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl relative text-white  lg:top-[14px] ">
              {" "}
              8
            </span>
          </div>
          <div className="digit-box lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl relative  lg:top-[14px]  font-semibold">
              {" "}
              9
            </span>
          </div>
          <div className="filled-true text-white lg:h-[4rem] w-[4rem] bg-default text-center">
            <span className="mont-serif text-xl relative lg:top-[14px]    text-white">
              {" "}
              10
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IntrestC;
