import React from 'react'
import Upcommingeventslider from './CompanyEvent/Upcommingeventslider'
import AttendedTable from './CompanyEvent/AttendedTable'

export default function MemberEvent() {
  return (
    <>
    <div   className="lg:w-[152vh] xs:w-[43vh] mx-auto xs:mt-8">
    <Upcommingeventslider/>
    </div>
    <AttendedTable/>
    </>
  )
}
