import React from 'react'
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import Form from './Form';
import Searchtab from '../../Searchtab';
import Sidebar from '../../Sidebar';
import Footer from '../../Footer';
export default function Neweventform() {
  return (
    <>
    <Sidebar/>
    <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
    <header   className="header bg-white shadow py-4 px-4">
     <Searchtab/>
    </header>
    <div   className="main-content flex flex-col flex-grow">
      <div   className="lg:p-10 bg-about text-center">
        <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125]	 ">
         New Event 
        </h1>
      </div>
<div className='mb-8'>
  <Form/>
</div>
           

   
    </div>
  </main>
  <Footer/>
  </>
  )
}
