import React, { useState ,useEffect} from "react";
import {  useNavigate } from "react-router-dom";
import Myeventsslider from "./Eventshome/Myeventsslider";
import UpcommingEventsslider from "./Eventshome/UpcommingEventsslider";
import Galleryslider from "./Eventshome/Galleryslider";
import Searchtab from "../Searchtab";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import SideMobile from "../SideMobile";
export default function Eventstock() {
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);
  return (
    
    <>
   <div className="sm:hidden lg:block">
    <Sidebar toggleModal={toggleModal}/>
    </div>
<div className="lg:hidden sm:block">
<SideMobile/>
</div>
      <main   className=" main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header   className="xs:hidden  header bg-white shadow py-4 px-4">
          <Searchtab/>
        </header>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="lg:p-10 bg-about text-center sticky">
            <h1   className="text-center mont-serif font-bold text-2xl text-[#005125]	xs:p-[22px] xs:mt-[6px] ">
              Events
            </h1>
          </div>
          <div   className="event-shadow xs:h-[20.3rem] ">
          <div   className="lg:w-[150vh] lg:mx-auto xs:w-[40vh]xs:mx-auto xs:ml-[1rem]">
            <Myeventsslider />
          </div>
          </div>

          <div   className="lg:w-[150vh] lg:mx-auto xs:w-[40vh]xs:mx-auto ">
            <UpcommingEventsslider />
          </div>
          <div   className="lg:w-[150vh] lg:mx-auto lg:mb-8 xs:w-[40vh]xs:mx-auto">
            <Galleryslider />
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
}
