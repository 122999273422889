import React from 'react'

export default function IntrestA() {
  return (
 <>
 
   <div className="flex flex-col lg:gap-2">
   <div className=" xs:relative xs:top-[0.5rem] lg:text-y inline-flex flex-col ml-[2rem] xs:gap-1">

    
          <h3 className=" xs:text-[20px] lg:text-left text-black mont-serif lg:text-2xl inline lg:mt-[16px]">
            Q: What sparks your interest today
          </h3>
          <p className=" xs:contents text-left text-[#676767] mont-serif inline relative top-[7px] left-[41px]">
            Choose as many as you like
          </p>
        </div>
          <div className="flex-wrap justify-start mt-[2.5rem] gap-3 lg:w-[max-content]">
            <div className=" xs:flex-wrap lg:flex gap-2 ml-[2rem]">
              <button className="filled-true p-[3px] lg:lg:w-[84px] xs:w-[6rem] mont-serif text-white h-[40px]">
                no.1
              </button>
              <button className=" xs:ml-[4px] bg-default  p-[3px] lg:w-[185px] xs:w-[10rem] mont-serif h-[40px]">
                Second Option
              </button>
              <button className="  xs:ml-[4px] bg-default  p-[3px] lg:w-[142px] mont-serif  h-[40px] xs:w-[6rem] ">
                # three
              </button>
              <button className=" xs:mt-[6px]  bg-default  p-[3px] lg:w-[176px] mont-serif h-[40px] xs:w-[8rem]">
                next choice
              </button>
            </div>
          </div>

          <div className=" xs:flex-wrap  lg:flex lg:flex-row gap-2 ml-[2rem] xs:mt-[1rem]">
            <button className="filled-true p-[3px] lg:w-[10rem] mont-serif text-white h-[40px] xs:w-[6rem]">
          lorem lps
            </button>
            <button className=" xs:ml-[4px] bg-default p-[3px] lg:w-[185px] mont-serif h-[40px] xs:w-[6rem]">
             choice
            </button>
            <button className=" xs:ml-[4px] filled-true text-white p-[3px] lg:w-[142px] mont-serif h-[40px] xs:w-[6rem]">
            seven
            </button>
            <button className=" xs:ml-[4px] bg-default p-[3px] lg:w-[176px] mont-serif h-[40px] xs:w-[4rem]">
              eight
            </button>

            <button className=" xs:mt-[6px] filled-true text-white p-[3px] lg:w-[176px] mont-serif h-[40px] xs:w-[9rem]">
              optimus prime
            </button>
          </div>
        </div>
 </>
  )
}
