import React from "react";
import logo from "./AdminImage/logo-1.png";
import avatar from "./AdminImage/avatar.png";
import { Link } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { BiMessageRoundedError } from "react-icons/bi";
import { MdSecurity } from "react-icons/md";
export default function Sidebar() {
  return (
    <>
      <aside className="sidebar w-64 lg:h-[96%] md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-card">
        <div className="sidebar-header flex items-center justify-center py-4 lg-h-[100vh]">
          <div className="inline-flex">
            <span className="inline-flex flex-row items-center cursor-pointer">
              <img src={logo} className="w-[auto] h-[58px]" alt="" />
              <span className="leading-10 text-gray-100 text-2xl font-semi-bold uppercase mont-serif text-[20px] ml-[12px]">
                DTEC RABBIT
              </span>
            </span>
          </div>
        </div>
        <div className="flex items-center gap- ml-[32px]">
          <img
            className="object-cover w-auto h-[58px] rounded-full"
            src={avatar}
            alt=""
          />

          <div className="cursor-pointer">
            <h1 className="text-lg text-white mont-serif text-[17px] cursor-pointer">
              user name
            </h1>

            <p className="text-sm text-white text-[10px] cursor-pointer">
              job title / position
            </p>
          </div>
        </div>
        <div className="sidebar-content px-4 py-6">
          <ul className="flex flex-col w-full">
            <Link to="/admin">
              <li className="my-px">
                <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                  style={{ borderRadius: "50px" }}
                >
                  <span className="flex items-center justify-center text-lg  ">
                    <AiFillHome className="text-white" />
                  </span>
                  <span className="ml-3 mont-serif text-white">Home</span>
                </span>
              </li>
            </Link>
            <Link to="/admin-member">
              {" "}
              <li className="my-px">
                <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                  style={{ borderRadius: "50px" }}
                >
                  <span className="flex items-center justify-center text-lg  ">
                    <BsFillPersonFill className="text-white" />
                  </span>
                  <span className="ml-3 mont-serif text-white">Members</span>
                </span>
              </li>
            </Link>

            <Link to="/admin-Event">
              {" "}
              <li className="my-px">
                <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                  style={{ borderRadius: "50px" }}
                >
                  <span className="flex items-center justify-center text-lg  ">
                    <SlCalender className="text-white" />
                  </span>
                  <span className="ml-3 mont-serif text-white">Events</span>
                </span>
              </li>
            </Link>


<Link to="/admin-complain" >

            <li className="my-px">
              <span
                className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span className="flex items-center justify-center text-lg  ">
                  <BiMessageRoundedError className="text-white" />
                </span>
                <span className="ml-3 mont-serif text-white">Complaints</span>
              </span>
            </li>
            </Link>

      <Link to="/admin-privacy" >  
            <li className="my-px">
              <span
                className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span className="flex items-center justify-center text-lg  ">
                  <MdSecurity className="text-white" />
                </span>
                <span className="ml-3 mont-serif text-white">Privacy</span>
              </span>
            </li>
            </Link>   
          </ul>
        </div>
      </aside>
    </>
  );
}
