import { useState, useEffect } from "react";
 
 import logo from "./logo.png";
import verifycode from "../../assets/welcomepage/otp.png"
import { Link } from "react-router-dom";


export default function Verifycode() {

    
// otp 
const [otp, setOtp] = useState(["", "", "", ""]);

const handleChange = (e, index) => {
  const newOtp = [...otp];
  newOtp[index] = e.target.value;
  setOtp(newOtp);
};

// otp end 

  const [showTimer, setShowTimer] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState(10);

  useEffect(() => {
    let timerId;

    if (showTimer && secondsLeft > 0) {
      timerId = setInterval(() => {
        setSecondsLeft(secondsLeft - 1);
      }, 1000);
    } else if (showTimer) {
      setShowTimer(false);
      setSecondsLeft(10);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [showTimer, secondsLeft]);

  function handleClick() {
    setShowTimer(true);
  }
  return (
    <>
    {/* verify otp screen   */}
      <section   className="text-gray-600 body-font bg-11 h-[100vh] bg-banner">
        <div   className="lg:container px-5 py-4 lg:mx-auto">
          <div   className="flex flex-wrap -mx-4 -mb-10 text-center lg:gap-8">
            <div   className="lg:w-[44%] md:w-1/2 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-1  lg-w-[43%] lg:h-[92vh] bg-white">
              <h2   className="text-black text-lg font-semibold  mb-5 lg:mt-[6rem] mont-serif relative lg:bottom-11">
                VERIFY CODE
              </h2>
              <div   className="container mx-auto">
                  <img
                      className="rounded-lg  mx-auto object-cover lg:h-[10rem] w-[auto]"
                    src={verifycode}
                 alt=""
                  />
                </div>
              {/* email input  */}
              <div   className="flex justify-center items-center lg:mt-[4rem] lg:mb-16">
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
            className="text-black w-16 h-16 mx-4 text-4xl text-center border border-gray-300   bg-transparent rounded-lg focus:outline-none focus:border-indigo-500"
          maxLength={1}
          value={digit}
          onChange={(e) => handleChange(e, index)}
        />
      ))}
    </div>


              {/* email input end  */}

              <div
                  className="forgot-password flex flex-col gap-3 justify-center mt-6"
                style={{ alignItems: "center" }}
              >
                {!showTimer && (
                  <button
                    style={{ borderRadius: "11px", fontSize: "14px" }}
                      className="mont-serif xs:w-[15rem] text-black button-1 border-0 py-1 px-8 focus:outline-none rounded text-lg lg:w-[48%]"
                    onClick={handleClick}
                  >
                    confirm
                  </button>
                )}
                {showTimer && (
                  <div   className="flex gap-2  lg:w-[48%] justify-center border rounded bg-transparent lg:p-[2px] focus:outline-none text-lg drop-shadow-md shadow-lg">
                    <span   className="text-black xs:w-[15rem]"> send again in</span>{" "}
                    <p   className="text-black xs:w-[15rem]">{` ${secondsLeft}s`}</p>
                  </div>
                )}
<Link to="/login" >
                <button
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                    className=" xs:w-[15rem] mont-serif lg:w-[18rem] text-black  bg-white border-0 lg:p-[2px] focus:outline-none hover:bg-teal-100 rounded text-lg drop-shadow-md shadow-lg"
                >
                  Go back
                </button>
                </Link>
              </div>
            </div>

            {/* flex-2  */}
            <div   className="sm:w-1/2  px-4 bg-[#00000012] lg:p-[14px] xs:hidden lg:block">
              <div   className="flex flex-row justify-end gap-2">
                <button
                  style={{ borderRadius: "6px", fontSize: "14px" }}
                    className="mont-serif border  text-white   lg:w-[9rem] lg:p-[2px] focus:outline-none text-lg drop-shadow-md shadow-lg"
                >
                  Contact us
                </button>
                {/*  */}
                <button
                  style={{ borderRadius: "6px", fontSize: "14px" }}
                    className="mont-serif bg-green-800  text-white border-0  lg:w-[9rem] lg:p-[2px] rounded text-lg drop-shadow-md shadow-lg"
                >
                  Login
                </button>
              </div>

              <div
                  className="flex flex-col justofy-center align-middle"
                style={{ alignItems: "center" }}
              >
                <h1     className="text-white text-lg font-semibold lg:mt-20   relative lg:bottom-11 mont-serif">
                  {" "}
                  DTEC RABBIT
                </h1>

                <div   className="container mx-auto">
                  <img
                      className="rounded-lg shadow-lg mx-auto object-cover lg:h-[15rem] w-[auto]"
                    src={logo}
                    alt=""
                  />
                </div>
              </div>

              <div
                  className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[8.9rem]"
                style={{ alignItems: "center" }}
              >
                <button
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                    className=" lg:w-[278px]  mont-serif text-black bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg "
                >
                  App store
                </button>

                <button
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                    className=" lg:w-[278px]   mont-serif  text-black  bg-white border-0 lg:p-[2px] focus:outline-none hover:bg-teal-100 rounded text-lg drop-shadow-md shadow-lg"
                >
                  Google play
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
