import React from 'react'
import { BsFillTelephoneFill,BsFillEyeFill,BsShareFill } from "react-icons/bs";
import { AiOutlineMail,AiOutlineGlobal } from "react-icons/ai";
import { Link } from 'react-router-dom';

function Roasttable() {
  return (
    <>
     <div   className="lg:container lg:mx-auto">
        <div   className="flex flex-col text-center w-full mb-20"></div>
        <div   className=" lg:w-[90%] mx-auto overflow-auto">
          <table   className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl"></th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl">
                  Member Name
                </th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                  Job Title
                </th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                  Segment
                </th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                  Action
                </th>
             
              </tr>
            </thead>
            <tbody>
              <tr>
           
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
<div   className="avatr">
<img
  src="https://tecdn.b-cdn.net/img/new/avatars/2.webp"
      className="w-[16%] rounded-full"
  alt="Avatar" />
</div>
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
<div   className="flex flex-row items-center lg:gap-[15px]">
<div   className="flex flex-col item-center">
    <span className='text-[#83b89b]'><BsFillTelephoneFill/></span>
    <span className='text-[#005125] mont-serif '>call</span>
</div>
    {/*  */}
<div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineMail/></span>
    <span className='text-[#005125] mont-serif '>mail</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsFillEyeFill/></span>
    <span className='text-[#005125] mont-serif '>view</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineGlobal/></span>
    <span className='text-[#005125] mont-serif '>website</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsShareFill/></span>
    <span className='text-[#005125] mont-serif '>share</span>
    </div>

    
{/*  */}

</div>
                </div>
                </td>
              </tr>
              <tr>
                
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                 <img
  src="https://tecdn.b-cdn.net/img/new/avatars/4.webp"
      className="w-[16%] rounded-full"
  alt="Avatar" />
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                  <div   className="flex flex-row lg:gap-[15px]">
<div   className="flex flex-row items-center lg:gap-[15px]">
<div   className="flex flex-col item-center">
    <span className='text-[#83b89b]'><BsFillTelephoneFill/></span>
    <span className='text-[#005125] mont-serif '>call</span>
</div>
    {/*  */}
<div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineMail/></span>
    <span className='text-[#005125] mont-serif '>mail</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsFillEyeFill/></span>
    <span className='text-[#005125] mont-serif '>view</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineGlobal/></span>
    <span className='text-[#005125] mont-serif '>website</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsShareFill/></span>
    <span className='text-[#005125] mont-serif '>share</span>
    </div>

    
{/*  */}

</div>
                </div>

                </div>
                </td>
              </tr>
              <tr>
                
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                 <img
  src="https://tecdn.b-cdn.net/img/new/avatars/22.webp"
      className="w-[16%] rounded-full"
  alt="Avatar" />
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                  <div   className="flex flex-row lg:gap-[15px]">
<div   className="flex flex-row items-center lg:gap-[15px]">
<div   className="flex flex-col item-center">
    <span className='text-[#83b89b]'><BsFillTelephoneFill/></span>
    <span className='text-[#005125] mont-serif '>call</span>
</div>
    {/*  */}
<div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineMail/></span>
    <span className='text-[#005125] mont-serif '>mail</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsFillEyeFill/></span>
    <span className='text-[#005125] mont-serif '>view</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineGlobal/></span>
    <span className='text-[#005125] mont-serif '>website</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsShareFill/></span>
    <span className='text-[#005125] mont-serif '>share</span>
    </div>

    
{/*  */}

</div>
                </div>

                </div>
                </td>
              </tr>
              <tr>
                 
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                 <img
  src="https://tecdn.b-cdn.net/img/new/avatars/12.webp"
      className="w-[16%] rounded-full"
  alt="Avatar" />
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                  <div   className="flex flex-row lg:gap-[15px]">
<div   className="flex flex-row items-center lg:gap-[15px]">
<div   className="flex flex-col item-center">
    <span className='text-[#83b89b]'><BsFillTelephoneFill/></span>
    <span className='text-[#005125] mont-serif '>call</span>
</div>
    {/*  */}
<div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineMail/></span>
    <span className='text-[#005125] mont-serif '>mail</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsFillEyeFill/></span>
    <span className='text-[#005125] mont-serif '>view</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineGlobal/></span>
    <span className='text-[#005125] mont-serif '>website</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsShareFill/></span>
    <span className='text-[#005125] mont-serif '>share</span>
    </div>

    
{/*  */}

</div>
                </div>

                </div>
                </td>
              </tr>
              <tr>
                 
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                 <img
  src="https://tecdn.b-cdn.net/img/new/avatars/11.webp"
      className="w-[16%] rounded-full"
  alt="Avatar" />
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                  <div   className="flex flex-row lg:gap-[15px]">
<div   className="flex flex-row items-center lg:gap-[15px]">
<div   className="flex flex-col item-center">
    <span className='text-[#83b89b]'><BsFillTelephoneFill/></span>
    <span className='text-[#005125] mont-serif '>call</span>
</div>
    {/*  */}
<div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineMail/></span>
    <span className='text-[#005125] mont-serif '>mail</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsFillEyeFill/></span>
    <span className='text-[#005125] mont-serif '>view</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><AiOutlineGlobal/></span>
    <span className='text-[#005125] mont-serif '>website</span>
    </div>
    {/*  */}
    <div   className="flex flex-col items-center">
    <span className='text-[#83b89b]'><BsShareFill/></span>
    <span className='text-[#005125] mont-serif '>share</span>
    </div>

    
{/*  */}

</div>
                </div>

                </div>
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div   className="button mx-auto flex justify-center mt-8">
  <Link to="/league" >
<button     className="lg:w-[10rem] btn-dc border border-green-700 mont-serif text-black mx-auto " > Go Back</button>
</Link>          
</div>
      </div>
    </>
  )
}

export default Roasttable
