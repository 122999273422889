import React from "react";
import img from "./graphimage/building.png";
function Leaderboardactivity() {
  return (
    <>
      <div className=" lg:w-[70%] overflow-auto mt-2 lg:pl-[19px] mb-9">
        <table className="table-auto w-full text-left whitespace-no-wrap">
          <thead>
            <tr>
              <th className="px-4 py-3 title-font tracking-wider text-xl border-y-2 text-green-700 mont-serif  rounded-tl rounded-bl"></th>
              <th className="px-4 py-3 title-font tracking-wider text-xl border-y-2 text-green-700 mont-serif  rounded-tl rounded-bl">
                Description
              </th>
              <th className="px-4 py-3 title-font tracking-wider text-xl border-y-2 text-green-700 mont-serif ">
                Date
              </th>
              <th className=" title-font tracking-wider text-xl border-y-2 text-green-700 mont-serif ">
                Points Earned
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-3 border-y-2">
                <div
                  className="bg-text inline-block xs:w-max	rounded-md p-2"
                  style={{ borderRadius: "20px" }}
                >
                  <img className="h-[42px]" src={img} alt="" />
                </div>
              </td>
              <td className="px-4 py-3 text-[#005125]">
                Lorem ipsum dolor sit amet.
              </td>
              <td className="px-4 py-3 text-[#005125]">12/12/12</td>
              <td className="px-4 py-3 text-[#005125]">15</td>
              <td className="w-10 text-center"></td>
            </tr>
            <tr>
              <td className="px-4 py-3 border-y-2">
                <div
                  className="bg-text inline-block xs:w-max	rounded-md p-2"
                  style={{ borderRadius: "20px" }}
                >
                  <img className="h-[42px]" src={img} alt="" />
                </div>
              </td>
              <td className="border-t-2 border-gray-200 px-4 py-3 text-[#005125]">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              </td>
              <td className="border-t-2 border-gray-200 px-4 py-3 text-[#005125]">
                12/12/12
              </td>
              <td className="border-t-2 border-gray-200 px-4 py-3 text-[#005125]">
                25{" "}
              </td>
            </tr>
            <tr>
              <td className="px-4 py-3 border-y-2">
                {" "}
                <div
                  className="bg-text inline-block xs:w-max	rounded-md p-2"
                  style={{ borderRadius: "20px" }}
                >
                  {" "}
                  <img className="h-[42px]" src={img} alt="" />
                </div>
              </td>

              <td className="border-t-2 border-gray-200 px-4 py-3 text-[#005125]">
                Lorem, ipsum.
              </td>
              <td className="border-t-2 border-gray-200 px-4 py-3 text-[#005125]">
                12/12/12
              </td>
              <td className="border-t-2 border-gray-200 px-4 py-3 text-[#005125]">
                40{" "}
              </td>
            </tr>
            <tr>
              <td className="px-4 py-3 border-y-2">
                {" "}
                <div
                  className="bg-text inline-block xs:w-max	rounded-md p-2"
                  style={{ borderRadius: "20px" }}
                >
                  {" "}
                  <img className="h-[42px]" src={img} alt="" />
                </div>
              </td>

              <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-[#005125]">
                Lorem ipsum dolor sit.
              </td>
              <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-[#005125]">
                12/12/12
              </td>
              <td className="border-t-2 border-b-2 border-gray-200 px-4 py-3 text-[#005125]">
                120{" "}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default Leaderboardactivity;
