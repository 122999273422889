import React from "react";

export default function IntrestB() {
  return (
    <>
        <div className="flex flex-col lg:gap-2">
   <div className="text-y inline-flex flex-col ml-[2rem]">
          <h3 className="text-left text-black mont-serif text-2xl inline mt-[16px]">
            Q: Lorem ipsum dolor sit amet consectetur adipisicing elit.?
          </h3>
          <p  className=" xs:content text-left text-[#676767] mont-serif inline relative top-[7px] left-[41px] opacity-0">
            Choose as many as you like
          </p>
        </div>
       

          <div className="flex lg:flex-row xs:flex-col xs:justify-center gap-2 ml-[2rem] lg:mt-[4rem] fade-in-right xs:mx-auto">
            <button className="filled-true p-[3px] lg:w-[10rem]   xs:w-[20rem] mont-serif text-white h-[40px]">
          lorem lps
            </button>
            <button className="bg-default p-[3px] lg:w-[185px]   xs:w-[20rem] mont-serif h-[40px]">
             choice
            </button>
            <button className="filled-true text-white p-[3px] lg:w-[142px]   xs:w-[20rem] mont-serif h-[40px]">
            seven
            </button>
            <button className="bg-default p-[3px] lg:w-[176px]   xs:w-[20rem] mont-serif h-[40px]">
              eight
            </button>

            <button className="filled-true text-white p-[3px] lg:w-[176px]   xs:w-[20rem] mont-serif h-[40px]">
              optimus prime
            </button>
          </div>
        </div>
    </>
  );
}
