import React from "react";

export default function Ourcommunity(props) {
  return (
    <>
      <section   className=" body-font">
        <h1     className="text-2xl title-font mb-4 text-[#005125] tracking-tight text-center mont-serif font-semibold flex flex-col">
          OUR Community
        </h1>
        <div   className="lg:container mx-auto flex flex-col justify-center items-center">
          <img
              className="lg:w-[1110px] lg:h-[439px] md:w-3/6 w-5/6 mb-10  object-center rounded"
            src={props.image}
            alt=""
          />
          <div   className="lg:w-[86%] xs:w-[80%] lg:mx-auto">
            <p   className="mb-2 leading-relaxed mont-serif">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              </p>

              <p   className="mt-2 leading-relaxed mont-serif">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              </p>

              <p   className=" mb-6 mt-2 leading-relaxed mont-serif">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              </p>

           
           
          </div>
        </div>
      </section>
    </>
  );
}
