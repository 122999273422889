import React from "react";
import img from "../AdminImage/imagecard.png";
import {  useNavigate } from "react-router-dom";

function AdminList() {
  const navigate = useNavigate();

const handleteam = ()=>{

  navigate(`/admin-leadership-team`)
}

const handlelist = ()=>{

  navigate(`/admin-leagueList`)
}

const handlecencom = ()=>{

  navigate(`/admin-Cencom`)
}
  return (
    <>
      <h1 className="text text-start mont-serif tracking-normal	lg:text-[23px] xs:ml-3">
        Admin List
      </h1>

      <div className=" flex flex-row  gap-4  lg:w-[682px] lg:justify-center xs:justify-start  ">
        <div className="flex flex-col">
          <div onClick={handleteam}  className="xs:ml-3 fade-in-top bg-home cursor-pointer lg:w-[289px] lg:h-[290px] xs:h-[12rem] xs:w-[14rem]">
            <div className="img">
              {" "}
              <img
                src={img}
                alt=""
                className="xs:h-[12rem] xs:w-[14rem] absolute w-[289px] h-[290px] opacity-[0.2]"
              />
            </div>
            <h1 className="text mont-serif text-center lg:mt-[15rem] xs:mt-[9rem]">
              Leadership Team
            </h1>
          </div>
        </div>

        <div className="flex flex-col gap-3 fade-in-bottom">
          <div  onClick={handlelist} className=" bg-home cursor-pointer lg:w-[289px] lg:h-[139px] xs:w-[10rem] xs:h-[5.5rem]">
            <div className="img">
              {" "}
              <img
                src={img}
                alt=""
                className="  xs:h-[5.5rem] absolute w-[289px] h-[290px] opacity-[0.2]"
              />
            </div>
            <h1 className=" xs:mt-[3rem] text mont-serif text-center lg:mt-[6rem]">
              League List
            </h1>
          </div>
          <div className=" bg-home cursor-pointer  lg:w-[289px] lg:h-[139px] xs:w-[10rem] xs:h-[5.5rem] " onClick={handlecencom}>
            <div className="img">
              {" "}
              <img
                src={img}
                alt=""
                className=" absolute w-[289px] h-[139px]   xs:h-[5.5rem] opacity-[0.2]"
              />
            </div>
            <h1 className="text mont-serif text-center xs:mt-[3rem] lg:mt-[6rem] ">Cencom</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminList;
