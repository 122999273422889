import React from 'react'
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import Sidebar from '../../Sidebar';

export default function OtherUpcommingeventslider() {
  const featuredsata = [
    {
      name: "Apple",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "samsung",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "lenovo",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "mi",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "poco",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "realme",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "lenovo",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "mi",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "poco",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
    {
      name: "realme",
      image: "https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png",
    },
  ];
  const scrollLeft = () => {
    document.getElementById("content").scrollLeft -= 400;
  };
  const scrollRight = () => {
    document.getElementById("content").scrollLeft += 400;
  };
  return (
    <>
    <Sidebar/>
    <div   className="relative lg:mx-auto mb-4">
      {/* <div   className="text-center py-4  text-xl font-bold"></div> */}
      <h1     className="text-2xl text-green-700 mont-serif lg:mt-[30px]">
        {" "}
       Others Upcomming Events
      </h1>

      <div className="absolute right-0 lg:top-[3.2rem] z-10 xs:top-[20px]">
        <button
          onClick={scrollLeft}
          className="lg:w-[1px] relative lg:top-[56px] lg:right-[69.2rem] lg:h-[4rem] p-2 m-2 rounded-full carousel-bg"
        >
          <FiChevronLeft className="relative lg:right-[7px] text-white" />
        </button>
        <button
          onClick={scrollRight}
          className="lg:w-[1px] relative lg:top-[56px] lg:left-[59px] lg:h-[4rem] p-2 m-2 rounded-full carousel-bg"
        >
          <FiChevronRight className="relative lg:right-[7px] text-white" />
        </button>
      </div>
      <div
        id="content"
        className="carousel p-4 flex items-center justify-start overflow-x-auto scroll-smooth scrollbar-hide lg:h-[auto] -m-4 mt-1"
      >
  {featuredsata.map((item) => (
          <div   className="carousel-card lg:ml-[-18px]" key={item.name}>
          <div className="p-4 lg:w-[265px] xs:w-[14rem] drop-shadow-xl">
               <div className="lg:h-[178px] lg:w-[235px] xs:h-max bg-opacity-75 px-8 pt-[2rem] shadow-inner pb-24 rounded-lg overflow-hidden text-center relative intrest-card h-[137px]  ">
                 <img src={item.image}   className="xs:relative xs:top-[28px] lg:relative  lg:top-[2.5rem]" alt="" style={{ marginTop: "-55px" }} />
                 <h5 className=" xs:w-[12.2rem] xs:top-[113px] xs:p-[3px] text-[17px] text-[#005125] mont-serif font-extrabold relative lg:top-[74px] lg:p-[3px] text-center text-bg bg-text right-[34px] lg:w-[30vh]">
                   <span>{item.name}</span>
                 </h5>
               </div>
             </div>
     </div>
  ))}
</div>
    </div>
  </>
  )
}
