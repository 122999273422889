import React from "react";
import Adminnav from "../Adminnav";

import AdminSidebar from "../AdminSidebar";
import AdminSearchbar from "../AdminSearchbar";
import Adminfooter from "../Adminfooter";

import { RiArrowRightSLine } from "react-icons/ri";
function AdminLeagueList() {
  return (
    <>
      <div className="lg:block xs:hidden">
        <AdminSidebar />
      </div>
      <div className="xs:block lg:hidden">
        <Adminnav />
      </div>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <div className="hidden lg:block">
          <header className="header bg-white shadow py-4 px-4 ">
            <AdminSearchbar />
          </header>
        </div>

        <div className="main-content flex flex-col flex-grow">
          <div className="xs:p-[25px] lg:p-10 bg-about text-center">
            <h1 className=" text-center mont-serif   font-extralight    	 text-2xl text-[#005125] ml-12">
              List of League
            </h1>
          </div>
          <div className="flex flex-col lg:mt-4 gap-[1.1rem] xs:mt-[4rem]">
            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>

            <div className="text icon-d  xs:w-[26rem]   flex flex-row items-center lg:gap-[37rem]  xs:gap-[11rem] border-b-[1px] border-[#A6A0A0]   justify-center w-[61%] mx-auto">
              <h3 className="mont-serif   font-extralight    	 leading-relaxed text-[#005125]">
                League Leads
              </h3>
              <div className="icon-d">
                <RiArrowRightSLine />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Adminfooter />
    </>
  );
}

export default AdminLeagueList;
