import React from "react";
import { useState } from "react";

import logo from "./logo.png";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/welcomepage/logo-1.png";

export default function Registercomapny() {
  //
  const [selectedOption, setSelectedOption] = useState("option1");

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }
  //
  const navigate = useNavigate();
  const verification = () => {
    navigate(`/verification`);
  };
  const routelogin = () => {
    navigate(`/login`);
  };
  return (
    <>
      <section className="text-gray-600 body-font bg-11 lg:h-[125vh] xs:h-[120vh] bg-banner">

      <div className="flex flex-row gap-[11rem] items-center  lg:hidden">
            <img src={Logo} className="mt-4 ml-1" alt="" />
<div className="flex fle-row gap-[5px]">

            <button
              onClick={routelogin}
              style={{ borderRadius: "5px", fontSize: "14px" }}
              className="xs:w-[5rem] mont-serif text-black bg-white border-0  focus:outline-none rounded text-lg"
            >
              Login
            </button>

            <button
                    style={{ borderRadius: "6px", fontSize: "14px" }}
                    className="xs:w-[5rem] mont-serif text-black bg-white border-0  focus:outline-none rounded text-lg"
                  >
                    Contact us
                  </button>
                  </div>
          </div>
        <div className="lg:container px-5 py-4 lg:mx-auto lg:h-[full]">
          <div className="flex flex-wrap -mx-4 -mb-10 text-center lg:gap-8">
            <div className="fade-in-left   lg:w-[44%] md:w-1/2 rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 bg-1  lg-w-[43%] bg-white mb-12">
              <h3 className="text-md text-start text-green-700 mont-serif leading-tight ">
                Please fill the regestration form to continue
              </h3>
              <form action="">
                <div className="xs:flex xs:flex-row xs:items-center">
                  <label
                    className="xs:relative xs:top-4  lg:left-[4rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    Full Name
                  </label>
                  <input
                    className="xs:relative xs:left-[23px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[63vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>

                <div className="flex lg:flex-row xs:flex-col lg:gap-[4.5rem] justify-center">
                  <div className="item-a xs:flex xs:flex-row xs:items-center">
                    <label
                      className="xs:relative xs:top-4 lg:left-[-1rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                      htmlFor="full-name"
                    >
                      Phone Number
                    </label>
                    <input
                      className="xs:relative xs:left-[7px] lg:w-[13rem] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto m-auto"
                      type="text"
                      id="full-name"
                    />
                  </div>
                  <div className="item-b xs:flex xs:flex-row xs:items-center">
                    <label
                      className="xs:relative xs:top-[19px] lg:left-[-1rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                      htmlFor="full-name"
                    >
                      Email ID
                    </label>
                    <input
                      className="xs:relative xs:left-[30px]  lg:w-[13rem] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto m-auto"
                      type="text"
                      id="full-name"
                    />
                  </div>
                </div>

                <div className="xs:flex xs:flex-row xs:items-center xs:gap-[44px] xs:mt-8">
                  <label
                    className="lg:left-[4rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    Choose League
                  </label>
                  <select
                    className="border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:mt-4  lg:w-[63vh] xs:w-[11rem]"
                    value={selectedOption}
                    onChange={handleOptionChange}
                  >
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </select>
                </div>

                <div className="xs:flex xs:flex-row xs:items-center">
                  <label
                    className=" xs:relative xs:top-[15px]  lg:left-[4rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    License No
                  </label>
                  <input
                    className=" xs:relative xs:left-[20px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[63vh] lg:mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>

                <div className="flex flex-row gap-[4.5rem] justify-center xs:mt-[1rem]">
                  <div className="mt-3">
                    <label
                      className="lg:left-[-1rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[8px] text-[13px] "
                      htmlFor="full-name"
                    >
                      License Type
                    </label>
                    <select
                      className="border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto w-full  lg:w-[13rem] xs:w-[8rem]"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <option value="option1">Option 1</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </select>
                  </div>
                  <div className="mt-3">
                    <label
                      className="lg:left-[-1rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[8px] text-[13px] "
                      htmlFor="full-name"
                    >
                      Office Type
                    </label>
                    <select
                      className="border-b border-gray-500 focus:border-blue-500 outline-none py-2 mt-2 mx-auto w-full lg:w-[13rem] xs:w-[8rem]"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      <option value="option1">Option 1</option>
                      <option value="option2">Option 2</option>
                      <option value="option3">Option 3</option>
                    </select>
                  </div>
                </div>

                <div className="xs:flex xs:flex-row xs:items-center xs:mt-3">
                  <label
                    className="xs:relative xs:top-[13px]  lg:left-[4rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name xs:relative xs:right:[9px]"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Company Founder
                  </label>
                  <input
                    className="xs:relative xs:left-[9px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[63vh] lg:mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>

                <div className="xs:flex xs:flex-row xs:items-center">
                  <label
                    className="xs:relative xs:top-4  lg:left-[4rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    Contact
                  </label>
                  <input
                    className="xs:relative xs:left-[42px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[63vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>

                <div className="xs:flex xs:flex-row xs:items-center">
                  <label
                    className="xs:relative xs:top-4  lg:left-[4rem] block text-start text-green-700 font-bold mb-1 mont-serif relative lg:top-[19px] text-[13px] "
                    htmlFor="full-name"
                  >
                    Website
                  </label>
                  <input
                    className="xs:relative xs:left-[42px] lg:text-[14px] border-b border-gray-500 focus:border-blue-500 outline-none py-2 lg:w-[63vh] mt-2 mx-auto m-auto"
                    type="text"
                    id="full-name"
                  />
                </div>
                <div className="mt-4 xs:ml-[6rem]">
                  <label
                    className="text-green-700  text-[13px] left-[-179px] font-bold  mont-serif relative top-[8px]"
              
                  >
                    Product Page
                  </label>
                  <textarea
                    id="textarea"
                    type="textarea"
                    className="xs:relative xs:left-[22px] xs:top-[-14px] relative lg:top-[19px]  block lg:w-[63vh] px-4 py-2 mt-2 text-green-700 bg-white border border-green-800 rounded-md focus:border-blue-500 focus:outline-none focus:ring mx-auto"
                    defaultValue={""}
                  />
                </div>

                <div className="mt-4 xs:ml-[6rem]">
                  <label
                    className="text-green-700  text-[13px] left-[-179px] font-bold  mont-serif relative top-[8px]"
              
                  >
                    Product Detail
                  </label>
                  <textarea
                    id="textarea"
                    type="textarea"
                    className="xs:relative xs:left-[22px] xs:top-[-14px] relative lg:top-[19px]  block lg:w-[63vh] px-4 py-2 mt-2 text-green-700 bg-white border border-green-800 rounded-md focus:border-blue-500 focus:outline-none focus:ring mx-auto"
                    defaultValue={""}
                  />
                </div>
              </form>
              <div
                className=" lg:hidden   forgot-password flex flex-col gap-3 justify-center  lg:mt-[8.9rem]"
                style={{ alignItems: "center" }}
              >
                <button
                  onClick={verification}
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                  className=" lg:w-[278px]  mt-3 mont-serif text-black bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg next  "
                >
                  Submit Application
                </button>

              
              </div>



            </div>

            {/* flex-2  */}
            <div className=" sm:w-1/2  px-4 bg-[#00000012] lg:p-[14px] lg:h-[93vh] xs:hidden lg:block ">
              <div className="flex flex-row justify-end gap-2 items-center">
                <button
                  style={{ borderRadius: "6px", fontSize: "14px" }}
                  className="mont-serif border relative top-1  text-white   lg:w-[9rem] lg:p-[2px] focus:outline-none text-lg drop-shadow-md shadow-lg"
                >
                  Contact us
                </button>
                {/*  */}
                <button
                  style={{ borderRadius: "6px", fontSize: "14px" }}
                  className="mt-2 mont-serif bg-green-800  text-white border-0  lg:w-[9rem] lg:p-[2px] rounded text-lg drop-shadow-md shadow-lg"
                >
                  Login
                </button>
              </div>

              <div
                className="flex flex-col justofy-center align-middle"
                style={{ alignItems: "center" }}
              >
                <h1 className="text-white text-lg font-semibold lg:mt-20   relative lg:bottom-11 mont-serif">
                  {" "}
                  DTEC RABBIT
                </h1>

                <div className="container mx-auto">
                  <img
                    className="rounded-lg shadow-lg mx-auto object-cover lg:h-[15rem] w-[auto]"
                    src={logo}
                    alt=""
                  />
                </div>
              </div>

              <div
                className="forgot-password flex flex-col gap-3 justify-center  lg:mt-[8.9rem]"
                style={{ alignItems: "center" }}
              >
                <button
                  onClick={verification}
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                  className=" lg:w-[278px]  mont-serif text-black bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg next  "
                >
                  Submit Application
                </button>

                <button
                  style={{ borderRadius: "11px", fontSize: "14px" }}
                  className=" lg:w-[278px]  mont-serif text-black bg-white text border-0 py-1 px-8 focus:outline-none rounded text-lg "
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
