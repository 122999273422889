import React, { useState } from "react";
import SideMobile from "../SideMobile";
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { RxCross1 } from "react-icons/rx";
import Logout from "../Home/Logout.png";
import { useNavigate } from "react-router-dom";
import Searchtab from "../Searchtab";

function Formupload() {
  const [formData, setFormData] = useState({
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };
  const back = () => {
    navigate(`/`);
  };

  return (
    <>
{isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="sm:hidden lg:block">
        <Sidebar toggleModal={toggleModal} />
      </div>
      <div className="lg:hidden sm:block">
        <SideMobile />
      </div>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="xs:hidden lg:block header bg-white shadow py-4 px-4 ">
          <Searchtab />
        </header>
        <div className="main-content flex flex-col flex-grow">
          <div className="lg:p-10 bg-about text-center">
            <h1 className="text-center mont-serif font-semibold text-2xl text-[#005125] xs:p-[22px] xs:mt-[6px]	 ">
              Form Uploads
            </h1>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-[50%] mx-auto lg:mt-[3rem] xs:mt-8"
          >
            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field1"
              >
                Membership Application
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field1"
                id="field1"
                value={formData.field1}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field2"
              >
                Visitor Information
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field2"
                id="field2"
                value={formData.field2}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field3"
              >
                One to One meetng
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field3"
                id="field3"
                value={formData.field3}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field4"
              >
                Testimonial
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field4"
                id="field4"
                value={formData.field4}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field5"
              >
                CBR Form
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field5"
                id="field5"
                value={formData.field5}
                onChange={handleChange}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
                htmlFor="field5"
              >
                Other
              </label>
              <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
                type="text"
                name="field5"
                id="field5"
                value={formData.field5}
                onChange={handleChange}
              />
            </div>

            <div className="flex flex-col gap-3 justify-center items-center mt-[3rem]">
              <button onClick={back}
                className="border border-green-900  w-[17rem] p-[4px] rounded-md mont-serif fade-in-right mb-12"
                type="submit"
              >
                Go Back
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Formupload;
