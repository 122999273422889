import React from "react";

function AdminCount() {
  return (
    <>
      <div className="flex flex-wrap	w-[79%] lg:gap-[4rem]  xs:gap-[2rem]  justify-center mx-auto lg:relative top-[7rem]">
        <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>

        <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
         <div className="flex flex-col">
          <div className="content-league  xs:h-[71px] lg:h-[74px] lg:w-[77px]  xs:w-[5rem]   shadow-xl">
            <p className="text-center lg:p-[21px] xs:mt-[21px]">70</p>
          </div>
          <div className="p">one to one</div>
        </div>
      </div>
    </>
  );
}

export default AdminCount;
