import React from "react";
import { Chart } from "react-google-charts";
import image from "../AdminImage/imagecard.png"
function MonthlyReport() {
  const chartOptions = {
    chartArea: { width: "100%", height: "100%" },
    width: "200px",
    height: "200px",
    // Other chart options...
  };
  const chartData = [
    ["Task", "Hours per Day"],
    ["Work", 8],
    ["Eat", 2],
    ["Sleep", 8],
    ["Exercise", 2],
  ];
  return (
    <>
      <div className="flex flex-col gap-3">
        <div className="item-1 ">
          <div className="lg:ml-[5rem] mt-[3rem]">
            <Chart
              chartType="PieChart"
              data={chartData}
              options={chartOptions}
              width="400px"
              height="300px"
            />
          </div>
        </div>

        <div className="item-2">
          <div className="flex flex-row items-center justify-center gap-[11rem]">
            <h1 className="text">Total Member</h1>
            <h1 className="text">1210</h1>
          </div>
          <div className="flex flex-row items-center justify-center gap-[12rem]">
            <h1 className="text">CBRS Given</h1>
            <h1 className="text">1210</h1>
          </div>
          <div className="flex flex-row items-center justify-center gap-[15rem]">
            <h1 className="text">Guest</h1>
            <h1 className="text">1210</h1>
          </div>
          <div className="flex flex-row items-center justify-center gap-[14rem]">
            <h1 className="text">Referals</h1>
            <h1 className="text">1210</h1>
          </div>
        
        </div>
        <div className="bg-home  h-[10rem] w-[23rem] mx-auto mb-[4rem]">
          <img src={image} className="absolute opacity-[0.3] lg:w-[24%] h-[10rem] xs:w-[23rem]" alt="" />
          <h3 className="text text-[18px] text-center mt-[7rem]">
            Members Awaiting Approval
          </h3>
        </div>
      </div>
    </>
  );
}

export default MonthlyReport;
