import React, { useState ,useEffect} from "react";

import Featuredslider from "./Members/Featuredslider";
import Companylisting from "./Members/Companylisting";
import image from "../assets/welcomepage/building.png";
import Searchtab from "./Searchtab";
import Sidebar from "./Sidebar";
import Footer from "../Components/Footer";
import SideMobile from "../Components/SideMobile";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Logout from "../Components/Home/Logout.png"
export default function Membercomponent() {
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);
  return (
    <>
    
    {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    
    
      <div className="sm:hidden lg:block">
        <Sidebar toggleModal={toggleModal} />
      </div>
      <div className="lg:hidden sm:block">
        <SideMobile />
      </div>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="xs:hidden lg:block header bg-white shadow py-4 px-4 ">
          <Searchtab />
        </header>
        <div className="main-content flex flex-col flex-grow">
          <div className="lg:p-10 bg-about text-center">
            <h1 className="text-center mont-serif font-semibold text-2xl text-[#005125] xs:p-[21px] xs:mt-[3px] ">
              Members
            </h1>
          </div>

          {/* slider  */}
          <div className=" lg:w-[153vh] lg:mx-auto xs:w-[43vh] mx-auto xs:mt-[24px]">
            <Featuredslider />
          </div>
          {/* slider end  */}
          {/* company listing
           */}
          <Companylisting image={image} />
          
        </div>
      </main>
      <Footer />
    </>
  );
}
