import React from 'react'
import Eventdetails from './Upcommingevents/Eventdetails'
import OtherUpcommingeventslider from './Upcommingevents/OtherUpcommingeventslider'
import Footer from '../Footer'

export default function Upcommingeventpage() {
  return (
    <>
    <Eventdetails/>

    <div     className="lg:mb-9 lg:w-[141vh] lg:relative lg:left-[100px] lg:mx-auto">
    <OtherUpcommingeventslider/>
    </div>
<Footer/>
    </>
  )
}
