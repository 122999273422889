import React from "react";
import MemberListing from "./MemberListing";
import MonthlyReport from "./MonthlyReport";
import AdminSidebar from "../AdminSidebar";
import AdminSearchbar from "../AdminSearchbar";
import Adminfooter from "../Adminfooter";
import Adminnav from "../Adminnav";

function MemberAdminmain() {
  return (
    <>
           <div className="lg:block xs:hidden">
        <AdminSidebar />
      </div>
      <div className="xs:block lg:hidden">
        <Adminnav />
      </div>

      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="xs:hidden lg:block header bg-white shadow py-4 px-4 ">
          <AdminSearchbar />
        </header>


<div className="main-content flex flex-col flex-grow">
<div className="lg:p-10 bg-about text-center">
            <h1 className="text-center mont-serif   font-extralight    	 text-2xl text-[#005125] ml-12 xs:p-[23px] xs:mt-[6px]">
            Members
            </h1>
          </div>
</div>

<div className="main-content flex flex-col flex-grow ">

        <div className="flex justify-start  lg:flex-row  xs:flex-col-reverse	 gap-4 rounded-md bounce-in-right">
          <MemberListing />
          <MonthlyReport />
        </div>
        </div>
      </main>
      <Adminfooter />
    </>
  );
}

export default MemberAdminmain;
