import React, { useState } from "react";
import logo from "../Admin/AdminImage/logo-1.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { RxCross1 } from "react-icons/rx";
import { AiFillHome } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { BiMessageRoundedError } from "react-icons/bi";
import { MdSecurity } from "react-icons/md";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
//

import {
  BsPeopleFill,
  BsFillShieldLockFill,
  BsFillChatFill,
} from "react-icons/bs";
import { HiLink } from "react-icons/hi";

import { HiRocketLaunch } from "react-icons/hi2";
import { FaCalculator } from "react-icons/fa";

const Adminnav = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-card">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
            <button
              onClick={toggleNavbar}
              className="inline-flex items-center justify-center p-2  text-white focus:outline-none "
            >
              {isOpen ? (
                <FiX className="h-6 w-6 focus:outline-0 hover:border-none text-white" />
              ) : (
                <FiMenu className="h-6 w-6 focus:outline-0 hover:border-none text-white" />
              )}
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link
                to="/"
                className="text-white text-xl font-bold font-all mont-serif"
              >
                DTEC RABBIT
              </Link>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4"></div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`sm:hidden transition-all duration-500 ease-in-out overflow-hidden ${
          isOpen ? "h-[15rem]" : "h-0"
        }`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link
            to="/admin"
            className="text-white hover:text-white lg:block px-3 py-2  text-base border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3 "
          >
            Home <AiFillHome className="text-white" />
          </Link>

          <Link
            to="/admin-member"
            className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif  flex items-center justify-center flex-row-reverse	font-bold  gap-3 "
          >
            <span className="relative left-[6px]"> Members </span>{" "}
            <img src="" className="h-[23px] relative left-[9px]" alt="" />
          </Link>
          <Link
            to="/admin-Event"
            className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
          >
            Events <HiRocketLaunch />
          </Link>
       
      
          <Link
            to="/admin-complain"
            className="text-gray-300 hover:bg-gray-700 hover:text-white lg:block px-3 py-2  text-base lg:font-medium border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center	font-bold  gap-3"
          >
            <BsFillShieldLockFill className="text-white" />
            Complains
          </Link>
          <Link
            to="/admin-privacy"
            className="text-gray-300 hover:bg-gray-700 hover:text-white  px-3 py-2  text-base  border-t-[1.1px] border-white  text-center mont-serif flex items-center justify-center flex-row-reverse	font-bold  gap-3"
          >
            Privacy <HiLink className="text-white" />
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default Adminnav;
