import React from "react";
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import Searchtab from "../../Searchtab";
import {  useNavigate } from "react-router-dom";

import Sidebar from "../../Sidebar";
import Footer from "../../Footer";
export default function Approvalpendingform() {
  
  return (
    <>
    <Sidebar/>
      <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header   className="header bg-white shadow py-4 px-4">
        <Searchtab/>
        </header>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="lg:p-10 bg-about text-center">
            <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125]	 ">
              New Event
            </h1>
          </div>
          <div   className="approvalpending-countdown ">
            <div   className="w-[60%] mx-auto flex flex-col justify-center mt-8 gap-[3rem]">
            <h1   className="text-3xl font-bold text-center mont-serif text-[#005125]">
              Approval Pending
            </h1>
            <img
              src="https://cdn.dribbble.com/users/11609495/screenshots/18251844/media/a4d3556d8b51796968cbcc63ea7c5abc.gif"   className="scale-in-center h-[auto] w-[200px] mx-auto"
              alt=""
            />
            <h1   className="text-3xl font-bold text-center mont-serif text-[#005125]">
              Thanks for Submission !
            </h1>

            <div   className="text">
              <p   className="mont-serif leading-3 text-center text-[#005125] mb-3">
                We have receive your application
              </p>
              <p   className="mont-serif leading-3 text-center text-[#005125]mt-3 ">
                Kindly wait for admin to approve your request
              </p>
            </div>
            <button     className="scale-in-hor-right  lg:w-[12rem] btn-dc border border-green-700 mont-serif text-black mx-auto mt-6">Go Back</button>
          </div>
          </div>
        </div>
      </main>
    <Footer/>
    </>
  );
}
