import React from 'react'
import AdminSidebar from "./AdminSidebar";
import AdminSearchbar from "./AdminSearchbar";
import AdminFooter from "./Adminfooter";
import Adminnav from "./Adminnav";

function AdminPrivacy() {
  return (
    <>
        <>
        <div className="lg:block xs:hidden">
        <AdminSidebar />
      </div>
      <div className="xs:block lg:hidden">
        <Adminnav />
      </div>
    <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
    <div className="hidden lg:block">
          <header className="header bg-white shadow py-4 px-4 ">
            <AdminSearchbar />
          </header>
        </div>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="    lg:p-10 bg-about text-center">
            <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125]	xs:p-[22px] xs:mt-[7px] ">
            Privacy Policy
            </h1>
          </div>

          {/* content static text */}
          <div   className="text-gray-600 ">
            <div   className="lg:container px-5 py-8 lg:mx-auto">
              <div   className=" lg:w-[89%] mx-auto text-center">
              
                <p   className=" text-lg w-[80%] mx-auto mont-serif">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ut repellendus quam debitis, sapiente, aperiam deleniti quos quod eligendi voluptatum, dolorum facilis maxime distinctio nihil. Ex tempore dolore praesentium numquam adipisci sequi magni libero tenetur tempora sed! Nihil a tempora ab harum sed laborum? Ea cupiditate laborum reiciendis earum dolor natus velit facere doloribus molestias ipsa itaque repudiandae, voluptatibus dignissimos accusamus odit. Sed sunt quos quibusdam possimus incidunt aspernatur! Ab expedita rem facere nisi cumque error excepturi iste amet, cupiditate autem perferendis hic placeat similique numquam temporibus quisquam eligendi sapiente, quidem delectus, ad in reprehenderit. Nulla id repellat aliquid architecto excepturi vitae nam voluptate doloremque obcaecati necessitatibus, deserunt similique adipisci hic, deleniti illum! Facilis veniam odio, autem aliquam itaque aut pariatur. Ea quos reiciendis nisi nulla at quisquam excepturi molestias ad! Explicabo vel sit adipisci delectus temporibus molestiae natus cum soluta dignissimos inventore harum aut tempora ipsam assumenda voluptate fuga iure, fugit suscipit officia ullam maiores eos minima odio. Veritatis quia voluptates voluptate sint, id optio quam voluptatum, consequuntur fuga sunt fugiat omnis repudiandae distinctio reprehenderit architecto recusandae ad asperiores? Voluptatibus, consequatur. Cum corrupti expedita mollitia, quas illum distinctio fuga modi cupiditate laudantium pariatur, qui 
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AdminFooter/>
  </>
    </>
  )
}

export default AdminPrivacy
