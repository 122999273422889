import React from "react";
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import {useNavigate } from "react-router-dom";
import Searchtab from "../../Searchtab";

export default function   Eventdetails() {

  const navigate = useNavigate();
  const handleEvent= ()=>{
    navigate(`/registeration-new-event`)
  }

  return (
    <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
      <header   className="header bg-white shadow py-4 px-4">
       <Searchtab/>
      </header>
      <div   className="main-content flex flex-col flex-grow">
        <div   className="lg:p-10 bg-about text-center   ">
          <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125]	 ">
            Upcomming Events
          </h1>
        </div>

        <section   className=" body-font mt-8">
          <div   className="container mx-auto flex flex-col justify-center items-center">
            <img
                className="lg:w-[818px] lg:h-[457px] md:w-3/6 w-5/6 mb-10  object-center rounded"
              src="https://media.istockphoto.com/id/1097080728/vector/group-of-cartoon-young-people-talking-isolated-on-white-background-vector-illustration.jpg?s=612x612&w=0&k=20&c=NPxKFZd3yFGFwJncaXbpJ72ob0bsR5-1WlN2NeyNrIk="
              alt=""
            />
            <div   className="lg:w-[86%] lg:mx-auto">
              <h1     className="text-2xl title-font mb-4 text-[#005125] tracking-tight text-center mont-serif font-semibold flex flex-col">
                Event Name
              </h1>

              <p   className=" text-[#424242] mb-2 leading-relaxed mont-serif">
                Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent
              </p>
              <p   className=" text-[#424242] mt-2 leading-relaxed mont-serif">
                Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                eu turpis molestie, dictum est a, mattis tellus. Sed dignissim,
                metus nec fringilla accumsan, risus sem sollicitudin lacus, ut
                interdum tellus elit sed risus. Maecenas eget condimentum velit,
                sit amet feugiat lectus. Class aptent taciti sociosqu ad litora
                torquent
              </p>
              <h1     className="text-2xl title-font mb-4 text-[#005125] tracking-tight text-left mt-9  mont-serif font-semibold ">
                Event Details
              </h1>
              <div>
                <ul   className="flex flex-col text-[#424242] list-disc lg:ml-[1rem]">
                  <li   className="list-dic text-[#424242] mont-serif">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </li>
                  <li   className="list-dic text-[#424242] mont-serif">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </li>
                  <li   className="list-dic text-[#424242] mont-serif">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </li>
                  <li   className="list-dic text-[#424242] mont-serif">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </li>
                  <li   className="list-dic text-[#424242] mont-serif">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </li>
                </ul>
              </div>
              <div     className="lg:mt-[5rem] flex flex-col justify-center items-center gap-2">
                <button onClick={handleEvent}     className="lg:w-[11rem] btn-ad mont-serif text-black ">
                Register now
                </button>
                <button     className="lg:w-[8rem] btn-dc border border-green-700 mont-serif text-black ">
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </section>
        {/* team sectiion end  */}
        
      </div>
    </main>
  );
}
