import React, { useState } from 'react';

function Onetoone() {
    const [formData, setFormData] = useState({
        field1: '',
        field2: '',
        field3: '',
        field4: '',
        field5: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission here
        console.log(formData);
      };
  return (
    <>
      <form onSubmit={handleSubmit}   className="xs:mt-6 w-[50%] mx-auto lg:mt-[3rem]">
      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field1">
          Meeting with
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field1"
          id="field1"
          value={formData.field1}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field2">
          Invited by:
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field2"
          id="field2"
          value={formData.field2}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field3">
          Meeting Location:
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field3"
          id="field3"
          value={formData.field3}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field4">
          Date and Time :
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field4"
          id="field4"
          value={formData.field4}
          onChange={handleChange}
        />
      </div>

      <div   className="mb-4">
        <label   className="block text-[#005125] font-bold mb-2 mont-serif" htmlFor="field5">
          Discussion Topic: 
        </label>
        <input
           className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
          type="text"
          name="field5"
          id="field5"
          value={formData.field5}
          onChange={handleChange}
        />
      </div>

    
<div    className="lg:mb-[4rem] flex flex-col gap-3 justify-center items-center mt-[3rem]">
      <button     className="btn-league w-[17rem] p-[4px] rounded-md mont-serif fade-in-left"   type="submit">Confirm</button>
      </div>

    </form>
    </>
  )
}

export default Onetoone
