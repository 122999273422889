
import AdminSidebar from "../AdminSidebar";
import AdminSearchbar from "../AdminSearchbar";
import AdminFooter from "../Adminfooter";
import Activitychart from "./Activitychart";
import AdminList from "./AdminList";
import AdminCount from "./AdminCount";
import Adminnav from "../Adminnav"
function AdminhomeMain() {
  return (
    <>
    <div className="lg:block xs:hidden">
      <AdminSidebar />
      </div>
      <div className="xs:block lg:hidden">
      <Adminnav/>
      </div>
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <div className="hidden lg:block">
          <header className="header bg-white shadow py-4 px-4 ">
            <AdminSearchbar />
          </header>
        </div>
        <div className="main-content flex flex-col flex-grow p-4">
          {/* <AdminCount/> */}

          <div className="flex lg:flex-row xs:flex-col gap-3 lg:mb-[5rem]">
            <div className="flex flex-col  gap-3 lg:w-[54%]">
              <Activitychart />
              <AdminList />
            </div>
            <div className="bg-[#ffff] shadow-md drop-shadow xs:mt-[4rem] xs:p-[3px] ">
              <AdminCount />
            </div>
          </div>
        </div>
      </main>

      <AdminFooter />
    </>
  );
}

export default AdminhomeMain;
