import React from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Companylisting(props) {
  const navigate = useNavigate();

  function handleViewDetailsClick() {
    navigate(`/description`);
  }
  return (
    <>
      <section   className="text-gray-600 body-font">
        <div   className="lg:container px-5 py-12 mx-auto">
          <div   className="flex flex-row text-center w-full lg:justify-center xs:justify-start xs:gap-[3rem] xs:h-[36px]      lg:gap-[55rem] mb-4">
            <h1   className="xs:ml-[9px]  text-[#005125] mont-serif text-2xl title-font mb-9">
              Company Listing
            </h1>
            <button     className="flex items-center lg:h-[34px] rounded-md text-[#005125] border py-2 px-8 focus:outline-none border-[#005125]">
              Filter by
            </button>
          </div>
          <div   className="lg:w-[auto] w-full mx-auto overflow-auto">
            <table   className="table-auto w-[85%] lg:mx-auto text-left whitespace-no-wrap">
              <thead>
                <tr   className="border-y-2">
                  <th   className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2 rounded-tl rounded-bl"></th>

                  <th   className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2 rounded-tl rounded-bl">
                    Member Name
                  </th>
                  <th   className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2">
                    Segment
                  </th>
                  <th   className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2">
                    Tagline
                  </th>
                  <th   className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2">
                    Information
                  </th>
                  <th   className="w-10 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2 rounded-tr rounded-br" />
                </tr>
              </thead>
              <tbody>
                <tr   className="border-y-2">
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    <div   className="">
                      <img
                        src={props.image}
                          className="lg:h-[41px] img-table"
                        alt=""
                      />
                    </div>
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td   className="px-4 py-3 mont-serif text-black ">
                 <button   className="btn-table" onClick={handleViewDetailsClick}>
                   view details
                    </button>
      </td>
                </tr>
                <tr   className="border-y-2">
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    <div   className="">
                      <img
                        src={props.image}
                          className="lg:h-[41px] img-table"
                        alt=""
                      />
                    </div>
                  </td>

                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td   className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button   className="btn-table"> view details</button>
                  </td>
                </tr>
                <tr   className="border-y-2">
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    <div   className="">
                      <img
                        src={props.image}
                          className="lg:h-[41px] img-table"
                        alt=""
                      />
                    </div>
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td   className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button   className="btn-table"> view details</button>
                  </td>
                </tr>
                <tr   className="border-y-2">
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    <div   className="">
                      <img
                        src={props.image}
                          className="lg:h-[41px] img-table"
                        alt=""
                      />
                    </div>
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td   className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td   className="px-4 py-3 mont-serif text-black ">
                   
                    <button   className="btn-table"> view details</button>
                  </td>
                </tr>
              </tbody>
            </table>
        
          </div>
          <div   className="float-right mt-4">
              <span   className="mb-3 text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                view more
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                    className="w-4 h-4 ml-2"
                  viewBox="0 0 24 24"
                >
                  <path d="M5 12h14M12 5l7 7-7 7" />
                </svg>
              </span>
            </div>
        </div>
      </section>
    </>
  );
}
