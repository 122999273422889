import React from 'react'
import { Chart } from "react-google-charts";

export const data = [
  ["Month", "Newly Registered"," Event Participants"],
  ["July", 1000,500],
  ["September", 1170,500],
  ["December", 660,500],
  ["June", 1030,500],
];




export default function Activitychart() {
  return (
    <>
<h3 className="text ml-4 mt-3">
  Activity
</h3>
    <div className="mt-[2rem]">
         <Chart
      chartType="Bar"
      width="100%"
      height="400px"
      data={data}
   
     
    /> 
    </div>
    </>
  )
}
