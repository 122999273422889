import React, { useEffect, useState} from 'react';

import Ourteam from "./Aboutus/Ourteam";
import Ourcommunity from "./Aboutus/Ourcommunity";
import banner from "../Components/Aboutus/banner-a.png";
import Searchtab from "./Searchtab";
import Sidebar from "./Sidebar";
import SideMobile from './SideMobile';
import Footer from "../Components/Footer"
import Logout from "../Components/Home/Logout.png";
import { RxCross1 } from "react-icons/rx";
import {  useNavigate } from "react-router-dom";

export default function Aboutus() {
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };  
  const [isModalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);
  return (
    <>
 {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}


<div className="sm:hidden lg:block">
    <Sidebar toggleModal={toggleModal}/>
    </div>
<div className="lg:hidden sm:block">
<SideMobile/>
</div>
      <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
     
      <header className="xs:hidden lg:block header bg-white shadow py-4 px-4 ">
          <Searchtab />
        </header>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="lg:p-10 bg-about text-center">
            <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125]	xs:mt-[5px] xs:p-[21px] ">
              About Us
            </h1>
          </div>

          {/* content static text */}
          <div   className="text-gray-600 ">
            <div   className="lg:container lg:px-5 py-8 mx-auto">
              <div   className=" w-[89%] mx-auto text-center">
                <h1   className="mont-serif text-[#005125] font-semibold text-2xl mb-4">
                  Our Vision
                </h1>
                <p   className="leading-relaxed text-lg  ">
                  Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                  Maecenas eget condimentum velit, sit amet feugiat lectus.
                  Class aptent taciti sociosqu ad litora torquent per conubia
                  nostra, per inceptos himenaeos. Praesent auctor rhoncus ex.
                  Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam
                  eu turpis molestie, dictum est a, mattis tellus. Sed
                  dignissim, metus nec fringilla accumsan, risus sem
                  sollicitudin lacus, ut interdum tellus elit sed risus.
                </p>
              </div>
            </div>
          </div>
          {/* content static end  */}
          {/* team section here  */}
          <Ourteam />
          <div className="mb-8">
          <Ourcommunity image={banner} />
          </div>
          {/* team sectiion end  */}
        </div>
      </main>
      <Footer/>
    </>
  );
}
