import React from 'react'
import {  FaSearch } from "react-icons/fa";
import AdminSidebar from "../AdminSidebar";
import AdminSearchbar from "../AdminSearchbar";
import AdminFooter from "../Adminfooter";
import Complainstock from "./Complainstock"
import Adminnav from "../Adminnav";

function Chatmain() {
  return (
  <>
<div className="lg:block xs:hidden">
        <AdminSidebar />
      </div>
      <div className="xs:block lg:hidden">
        <Adminnav />
      </div>

  
      <AdminSidebar />
      <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
        <header className="header bg-white shadow py-4 px-4">
         <AdminSearchbar/>
        </header>
        <div className="main-content flex flex-col flex-grow">
          <div className="lg:container lg:mx-auto">
            <div className="min-w-full border rounded lg:grid lg:grid-cols-3 ">
              <div className="border-r border-gray-300 lg:col-span-1">
                <div className="mx-3 my-3 bg-profile p-[20px] rounded-md">
                    <h6 className="mont-serif text-white text-left font-semibold relative bottom-2"> Your Messages</h6>
                  <div className="relative text-gray-600">
                    <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                      <FaSearch />
                    </span>
                    <input
                      type="search"
                      className="block w-full py-2 pl-10 bg-gray-100 rounded outline-none"
                      name="search"
                      placeholder="Search"
                      required=""
                    />
                  </div>
                </div>
                <ul className="overflow-auto h-[32rem]">
                  <li>
                    <a
                      href="//"
                      className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none"
                    >
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/09/12/12/14/man-3672010__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Jhon Don
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            25 minutes
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          bye
                        </span>
                      </div>
                    </a>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out bg-gray-100 border-b border-gray-300 cursor-pointer focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2016/06/15/15/25/loudspeaker-1459128__340.png"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Same
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            50 minutes
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good night
                        </span>
                      </div>
                    </span>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>

                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>


                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>

                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                    <span className="flex items-center px-3 py-2 text-sm transition duration-150 ease-in-out border-b border-gray-300 cursor-pointer hover:bg-gray-100 focus:outline-none">
                      <img
                        className="object-cover w-10 h-10 rounded-full"
                        src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                        alt="username"
                      />
                      <div className="w-full pb-2">
                        <div className="flex justify-between">
                          <span className="block ml-2 font-semibold text-gray-600">
                            Emma
                          </span>
                          <span className="block ml-2 text-sm text-gray-600">
                            6 hour
                          </span>
                        </div>
                        <span className="block ml-2 text-sm text-gray-600">
                          Good Morning
                        </span>
                      </div>
                      
                    </span>
                  </li>
                </ul>
              </div>
              <div className="hidden lg:col-span-2 lg:block">
                <div className="w-full">
                  <div className="relative flex items-center p-3 border-b border-gray-300 bg-profile ">
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src="https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083383__340.jpg"
                      alt="username"
                    />
                    <span className="block ml-2 font-bold text-gray-600">
                      Emma
                    </span>
                    <span className="absolute w-3 h-3 bg-green-600 rounded-full left-10 top-3"></span>
                  </div>

              <Complainstock/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <AdminFooter />
  
 
  </>
  )
}

export default Chatmain
