import React, { useState } from "react";
import { AiOutlineMail, AiTwotoneBell } from "react-icons/ai";
import { FaUserAlt } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar';
import {  useNavigate } from "react-router-dom";
import Searchtab from "../Searchtab";
import { RxCross1 } from "react-icons/rx";

import Footer from "../Footer";
import Logout from "../Home/Logout.png";
import SideMobile from "../SideMobile";
export default function Referallink(props) {
  const [isCopied, setIsCopied] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCopyClick = () => {
    // Copy logic here
    setIsCopied(true);
  
    // Show a toast notification
    toast.success('Link has been copied!', {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000,
    });
  
    // Reset state after a certain amount of time
    setTimeout(() => setIsCopied(false), 3000);
  };
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };
  const navigate = useNavigate();
  const handlelogout = () => {
    navigate(`/login`);
  };

  return (
  <>
    {isModalOpen && (
        <div className=" fade-in-top fixed z-10 overflow-y-auto top-0 w-full left-0">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-black opacity-75" />
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
              &#8203;
            </span>
            <div
              className="inline-block align-center  rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-[608px] h-full scale-in-center"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white flex flex-col items-center">
                <span
                  onClick={toggleModal}
                  className="relative left-[15rem] mt-[15px] text-[29px] cursor-pointer text-black"
                >
                  <RxCross1 />
                </span>
                <div className="img">
                  <img
                    src={Logout}
                    className="mx-auto h-[11rem] relative left-[12px]"
                    alt=""
                  />
                </div>
                <div className=" mont-serif text-2xl text-[#005125] text-center font-semibold  leading-relaxed mt-3">
                  Are You Sure You Want to logout
                  <br />
                  <span className="text-center"> From All devices</span>
                </div>

            
              </div>
              <div className=" lg:p-[40px] flex flex-col justify-center items-center bg-white mx-auto gap-2 ">
                <button onClick={handlelogout} className="text-black mont-serif delete p-[6px] w-[16rem]">
                  {" "}
                  Logout
                </button>
                <button
                  onClick={toggleModal}
                  className="text-black mont-serif dlt  rounded-md p-[6px] w-[16rem]"
                >
                  {" "}
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
  <div className="sm:hidden lg:block">
    <Sidebar toggleModal={toggleModal}/>
    </div>
<div className="lg:hidden sm:block">
<SideMobile/>
</div>
    <main   className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
    <header className="xs:hidden lg:block header bg-white shadow py-4 px-4 ">
          <Searchtab />
        </header>
        <div   className="main-content flex flex-col flex-grow">
          <div   className="    lg:p-10 bg-about text-center">
            <h1   className="text-center mont-serif font-semibold text-2xl text-[#005125] xs:p-[20px] xs:mt-[9px]	 ">
          Referal Link
            </h1>
          </div>

          {/* content static text */}

          <div   className="text-gray-600 ">
            <div   className="lg:container px-5 py-8 mx-aut">
             <div   className="flex lg:flex-row xs:flex-col items-center lg:gap-[11rem] lg:p-[19px]">
                <div   className="img">
                <img className='h-[25rem]  fade-in  ml-4 xs:fade-in-left' src={props.refImg} alt="ref" />
                </div>
                <div   className="flex flex-col items-center lg:gap-[3rem] xs:mt-[2rem] xs:gap-[1rem]">
                <div   className="text-link mont-serif">
                  <h3 className='text-[#6060e2] font-bold underline underline-offset-4'>http://www.360Community-dummyurlcom</h3>
                </div>
                <ToastContainer />
                <button
      className={`button-otp mont-serif text-black lg:p-[3px] w-[15rem] scale-in-hor-right ${
        isCopied ? 'bg-green-300 rounded-md scale-in-hor-right' : ''
      }`}
      onClick={handleCopyClick}
    >
      {isCopied ? 'Link has been copied!' : 'Copy link'}
    </button>

                </div>
             </div>
            </div>
          </div>
        </div>
      </main>
      <Footer/>
  </>
  )
}
