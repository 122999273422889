import React from "react";
import { FiFilter } from "react-icons/fi";
import img from "../AdminImage/building.png";
function MemberListing() {
  return (
    <>
      <div className=" lg:w-[61%] p-4-rounded-md">
        <div className="flex fex-row  left-[-2rem]  lg:justify-center xs:justify-start  lg:gap-[25rem] relative lg:top-[14px] xs:gap-[10rem]">
          <h1
            style={{ whiteSpace: "nowrap" }}
            className="   font-bold inline text-2xl text-[#005125] mont-serif relative left-[3rem]"
          >
            Member Listing
          </h1>
          <button className="p-1 justify-center lg:gap-[7px] lg:w-[11rem] flex items-center rounded-md border border-green-700 mont-serif">
            {" "}
            <span className="text-[#005125]">
              <FiFilter />
            </span>{" "}
            <span> Filter By </span>{" "}
          </button>
        </div>

        <div className=" mx-auto ">
          <div className="lg:w-[auto] w-full mx-auto overflow-auto mt-12">
            <table className="table-auto  text-left whitespace-no-wrap">
              <thead>
                <tr className="border-y-2">
                  <th className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2 rounded-tl rounded-bl"></th>

                  <th className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2 rounded-tl rounded-bl">
                    Member Name
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2">
                    Segment
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2">
                    Tagline
                  </th>
                  <th className="px-4 py-3 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2">
                    Information
                  </th>
                  <th className="w-10 title-font tracking-wider   mont-serif text-[#005125] font-semibold text-sm border-y-2 rounded-tr rounded-br" />
                </tr>
              </thead>
              <tbody>
                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    <button className="btn-table">view details</button>
                  </td>
                </tr>
                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>

                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>
                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>
                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>
                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>

                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>

                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>

                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>

                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>

                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>

                <tr className="border-y-2">
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    <div className="">
                      <img src={img} className="lg:h-[41px] img-table" alt="" />
                    </div>
                  </td>

                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    user name{" "}
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Accomodation
                  </td>
                  <td className="px-4 py-3 mont-serif text-[#005125]">
                    Add random phrase
                  </td>
                  <td className="px-4 py-3 mont-serif text-black ">
                    {" "}
                    <button className="btn-table"> view details</button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default MemberListing;
