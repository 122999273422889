import React from "react";

import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function Homecard(props) {
  const navigate = useNavigate();
  //
  const handlelistener = () => {
    navigate(`/members`);
  };
  const league = () => {
    navigate(`/league`);
  };
  const event = () => {
    navigate(`/event`);
  };
  const formupload = () => {
    navigate(`/formuploads`);
  };
  const navigateleader = () => {
    navigate(`/leaderboard`);
  };
 


  return (
    <>
      <motion.div
        
        className="  lg:flex lg:flex-wrap lg:-m-4 lg:mt-1 lg:lg:gap-[34px]   xs:grid xs:grid-cols-2  "
      >
        <div className="p-4  lg:w-[218px]  xs:w-[12rem]" onClick={navigateleader}>
          <div className="   lg:w-[245px]   lg:h-[150px] xs:h-[7rem] rounded-[20px] overflow-hidden text-center relative bg-home transform hover:scale-105 duration-500 ease-in-out cursor-pointer">
            <img
              src={props.bg}
              className="bg-grid absolute z-40  opacity-[0.3] h-[100%] w-[100%]"
              alt=""
            />

            <h5 className="text  font-extrabold relative lg:top-[105px] xs:mt-[4rem] text-center">
              Leaderboard
            </h5>
          </div>
        </div>

        <div className="p-4  lg:w-[218px]  xs:w-[12rem]" onClick={handlelistener}>
          <div className="   lg:w-[245px]   lg:h-[150px] xs:h-[7rem] rounded-[20px] overflow-hidden text-center relative bg-home transform hover:scale-105 duration-500 ease-in-out cursor-pointer">
            <img
              src={props.bg}
              className="bg-grid absolute z-40  opacity-[0.3] h-[100%] w-[100%]"
              alt=""
            />

            <h5 className="text  font-extrabold relative lg:top-[105px] xs:mt-[4rem] text-center">
              Members
            </h5>
          </div>
        </div>
    
      

        <div className="p-4  lg:w-[218px]  xs:w-[12rem]" onClick={event}>
          <div className="   lg:w-[245px]   lg:h-[150px] xs:h-[7rem] rounded-[20px] overflow-hidden text-center relative bg-home transform hover:scale-105 duration-500 ease-in-out cursor-pointer">
            <img
              src={props.bg}
              className="bg-grid absolute z-40  opacity-[0.3] h-[100%] w-[100%]"
              alt=""
            />

            <h5 className="text  font-extrabold relative lg:top-[105px] xs:mt-[4rem] text-center">
              Events
            </h5>
          </div>
        </div>



        
        <div className="p-4 lg:w-[218px] xs:w-[12rem]" onClick={league}>
          <div className=" lg:w-[245px]   lg:h-[150px] xs:h-[7rem] rounded-[20px] overflow-hidden text-center relative bg-home transform hover:scale-105 duration-500 ease-in-out cursor-pointer">
            <img
              src={props.bg}
              className="bg-grid absolute z-40  opacity-[0.3] h-[100%] w-[100%]"
              alt=""
            />

            <h5 className="text  font-extrabold relative lg:top-[105px] xs:mt-[4rem]  text-center">
              Leagues
            </h5>
          </div>
        </div>


        <div className="p-4  lg:w-[218px]  xs:w-[24rem]" onClick={formupload}>
          <div className="   lg:w-[245px]   lg:h-[150px] xs:h-[7rem] rounded-[20px] overflow-hidden text-center relative bg-home transform hover:scale-105 duration-500 ease-in-out cursor-pointer">
            <img
              src={props.bg}
              className="bg-grid absolute z-40  opacity-[0.3] h-[100%] w-[100%]"
              alt=""
            />

            <h5 className="text  font-extrabold relative lg:top-[105px] xs:mt-[4rem] text-center">
              Form Upload
            </h5>
          </div>
        </div>
        
      </motion.div>
    </>
  );
}
