import React from "react";
import logo from "../assets/welcomepage/logo-1.png";
import avatar from "../assets/welcomepage/avatar.png";
import { Link, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { BsPeopleFill, BsFillShieldLockFill ,BsFillChatFill} from "react-icons/bs";
import {  HiLink } from "react-icons/hi";

import { HiRocketLaunch } from "react-icons/hi2";
import { FaCalculator } from "react-icons/fa";
import { RxExit } from "react-icons/rx";
import icona from "../assets/welcomepage/icon-d.png"
export default function Sidebar(props) {


  const navigate = useNavigate();

  const handlelistener = () => {
    navigate(`/event`);
  };

  const navigatestatic = () => {
    navigate(`/privacy`);
  };

  const navigatelink = () => {
    navigate(`/referal`);
  };
  const navigateleague = () => {
    navigate(`/league`);
  };
 
  const navigateleaderbord = () => {
    navigate(`/leaderboard`);
  };
  const navigatehome = () => {
    navigate(`/`);
  };
const navigatechat = ()=>{
  navigate(`/chats`)
}
  return (
    <>
      <aside   className="sidebar w-64 lg:h-[96%] md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-card">
        <div   className="sidebar-header flex items-center justify-center py-4 lg-h-[100vh]">
          <div   className="inline-flex">
            <span
                className="inline-flex flex-row items-center cursor-pointer"
              onClick={navigatehome}
            >
              <img src={logo}   className="w-[auto] h-[58px]" alt="" />
              <span   className="leading-10 text-gray-100 text-2xl font-semi-bold uppercase mont-serif text-[20px] ml-[12px]">
                DTEC RABBIT
              </span>
            </span>
          </div>
        </div>
        <div     className="flex items-center gap- ml-[32px]">
          <img
                className="object-cover w-auto h-[58px] rounded-full"
            src={avatar}
            alt=""
          />

          <div   className="cursor-pointer" onClick={navigateleaderbord}>
            <h1
                  className="text-lg text-white mont-serif text-[17px] cursor-pointer"
              onClick={navigateleaderbord}
            >
              user name
            </h1>

            <p     className="text-sm text-white text-[10px] cursor-pointer">
              job title / position
            </p>
          </div>
        </div>
        <div   className="sidebar-content px-4 py-6">
          <ul   className="flex flex-col w-full">
            <Link to="/">
              <li   className="my-px">
                <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                  style={{ borderRadius: "50px" }} >
                  
                  <span   className="flex items-center justify-center text-lg  ">
                    <AiFillHome   className="text-white" />
                  </span>
                  <span   className="ml-3 mont-serif text-white">Home</span>
                </span>
              </li>
            </Link>

            <Link to="/aboutus">
              <li   className="my-px">
                <span
                    className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                  style={{ borderRadius: "50px" }}
                >
                  <span   className="flex items-center justify-center text-lg  text-white">
                    <BsPeopleFill   className="text-white" />
                  </span>
                  <span   className="ml-3">About us</span>
                </span>
              </li>
            </Link>

            <Link to="/members">
              <li   className="my-px">
                <span
                    className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                  style={{ borderRadius: "50px" }}
                >
               <img src={icona}   className="h-[23px]" alt="" />
                  <span   className="ml-3">Members</span>
                </span>
              </li>
            </Link>

            <li   className="my-px cursor-pointer" onClick={navigateleague}>
              <div
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span   className="flex items-center justify-center text-lg text-white ">
                  <HiRocketLaunch />
                </span>
                <span   className="ml-3">Leagues</span>
              </div>
            </li>
            <li onClick={handlelistener}   className="my-px cursor-pointer">
              <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span   className="flex items-center justify-center text-lg  text-white cursor-pointer">
                  <FaCalculator />
                </span>
                <span   className="ml-3">Events</span>
              </span>
            </li>

            <li   className="my-px cursor-pointer" onClick={navigatechat}>
              <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span   className="flex items-center justify-center text-lg text-white">
                  <BsFillChatFill />
                </span>
                <span   className="ml-3">Chats</span>
              </span>
            </li>

            <li   className="my-px cursor-pointer" onClick={navigatestatic}>
              <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span   className="flex items-center justify-center text-lg text-white">
                  <BsFillShieldLockFill />
                </span>
                <span   className="ml-3">Privacy</span>
              </span>
            </li>

            <li   className="my-px cursor-pointer" onClick={navigatelink}>
              <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span   className="flex items-center justify-center text-lg text-white">
                  <HiLink />
                </span>
                <span   className="ml-3">Referal</span>
              </span>
            </li>

            <li   className="my-px lg:absolute  lg:bottom-6 lg:w-[75%] cursor-pointer" onClick={props.toggleModal}>
              <span
                  className="flex flex-row items-center h-10 px-3 rounded-lg text-white mont-serif hover:bg-[#007033]  "
                style={{ borderRadius: "50px" }}
              >
                <span   className="flex items-center justify-center text-lg text-red-400 cursor-pointer">
                  <RxExit />
                </span>
                <span   className="ml-3">Logout</span>
              </span>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}
