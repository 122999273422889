import React from "react";
import bg from "../AdminImage/banner.png";
export default function LeagueMeeting() {
  return (
    <>
      <h1 className="text-2xl text-green-700 mont-serif lg:mt-[30px]  xs:ml-4">
        League Meeting
      </h1>
      <div className="flex flex-row justify-center gap-4 xs:relative xs:left-[7px]">
        <div className="bg-home lg:h-[214px] xs:h-[123px]  xs:w-[182px] lg:w-[34rem] mt-4 ">
          <img
            src={bg}
            className="absolute lg:h-[214px] lg:w-[34rem] xs:w-[178px] xs:h-[123px] rounded-[20px] opacity-[0.4] "
            alt=""
          />
          <h1 className="lg:text  mont-serif  xs:text-center xs:relative xs:top-[5rem] lg:mt-[10rem] lg:ml-[2rem] ">
            Pending Approval
          </h1>
        </div>

        <div className="bg-home lg:h-[214px] xs:h-[123px]   xs:w-[182px] lg:w-[34rem] mt-4 ">
          <img
            src={bg}
            className="absolute lg:h-[214px] lg:w-[34rem]  xs:w-[180px] xs:h-[123px]  rounded-[20px] opacity-[0.4] "
            alt=""
          />
          <h1 className="lg:text  mont-serif  xs:text-center xs:relative xs:top-[5rem] lg:mt-[10rem] lg:ml-[2rem] ">
            Pending Approved
          </h1>
        </div>
      </div>
    </>
  );
}
