import React from "react";
import { BsFillPencilFill } from "react-icons/bs";
import { AiFillDelete } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";
export default function Leagueleadtable(props) {
  return (
    <>
<div   className="flex fex-row  xs:gap-[7rem] xs:relative xs:top-8 justify-center lg:gap-[55rem] relative lg:top-[33px]">
<h1  style={{whiteSpace:"nowrap"}}   className="font-bold inline text-2xl text-[#005125] mont-serif relative left-[3rem]">League Lead</h1>
<button   className="p-1 justify-center lg:gap-[7px] lg:w-[11rem] flex items-center rounded-md border border-green-700 mont-serif"> <span   className="text-[#005125]"><FiFilter/></span> <span> Filter By </span> </button>
</div>

      <div   className="lg:container lg:mx-auto">
        <div   className="flex flex-col text-center w-full mb-20"></div>
        <div   className=" lg:w-[90%] mx-auto overflow-auto">
          <table   className="table-auto w-full text-left whitespace-no-wrap">
            <thead>
              <tr>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl"></th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl"></th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125] rounded-tl rounded-bl">
                  Member Name
                </th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                  Segment
                </th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                  Date
                </th>
                <th   className="px-4 py-3 title-font tracking-wider font-medium  text-md text-[#005125]">
                  Action
                </th>
             
              </tr>
            </thead>
            <tbody>
              <tr>
                <td   className="w-10 text-center">
                  <input name="plan" type="radio" />
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                <div   className="image-grid lg:w-[50px]">
                    <img src={props.glass}   className="h-[39px] mx-auto" alt="" />
                </div>
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                    <div   className="flex flex-row items-center"> <span   className="text-green-500"><BsFillPencilFill/></span> <p   className="underline text-green-600">Edit</p></div>
                    <div   className="flex flex-row items-center"> <span   className="text-red-500"><AiFillDelete/></span> <p   className="underline text-red-600">Delete</p></div>

                </div>
                </td>
              </tr>
              <tr>
                <td   className="border-t-2 border-gray-200 w-10 text-center">
                  <input name="plan" type="radio" />
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                <div   className="image-grid lg:w-[50px]">
                    <img src={props.glass}   className="h-[39px] mx-auto" alt="" />
                </div>
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                    <div   className="flex flex-row items-center"> <span   className="text-green-500"><BsFillPencilFill/></span> <p   className="underline text-green-600">Edit</p></div>
                    <div   className="flex flex-row items-center"> <span   className="text-red-500"><AiFillDelete/></span> <p   className="underline text-red-600">Delete</p></div>

                </div>
                </td>
              </tr>
              <tr>
                <td   className="border-t-2 border-gray-200 w-10 text-center">
                  <input name="plan" type="radio" />
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                <div   className="image-grid lg:w-[50px]">
                    <img src={props.glass}   className="h-[39px] mx-auto" alt="" />
                </div>
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                    <div   className="flex flex-row items-center"> <span   className="text-green-500"><BsFillPencilFill/></span> <p   className="underline text-green-600">Edit</p></div>
                    <div   className="flex flex-row items-center"> <span   className="text-red-500"><AiFillDelete/></span> <p   className="underline text-red-600">Delete</p></div>

                </div>
                </td>
              </tr>
              <tr>
                <td   className="border-t-2 border-b-2 border-gray-200 w-10 text-center">
                  <input name="plan" type="radio" />
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                <div   className="image-grid lg:w-[50px]">
                    <img src={props.glass}   className="h-[39px] mx-auto" alt="" />
                </div>
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                    <div   className="flex flex-row items-center"> <span   className="text-green-500"><BsFillPencilFill/></span> <p   className="underline text-green-600">Edit</p></div>
                    <div   className="flex flex-row items-center"> <span   className="text-red-500"><AiFillDelete/></span> <p   className="underline text-red-600">Delete</p></div>

                </div>
                </td>
              </tr>
              <tr>
                <td   className="border-t-2 border-b-2 border-gray-200 w-10 text-center">
                  <input name="plan" type="radio" />
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                <div   className="image-grid lg:w-[50px]">
                    <img src={props.glass}   className="h-[39px] mx-auto" alt="" />
                </div>
                </td>

                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  user name
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  Accomodation
                </td>
                <td   className="border-t-2 border-gray-200  px-4 py-3 mont-serif text-[#005124]">
                  26 Feb, 2023
                </td>
                <td   className="border-t-2 border-gray-200 px-4 py-3 text-lg text-gray-900">
                <div   className="flex flex-row lg:gap-[15px]">
                    <div   className="flex flex-row items-center"> <span   className="text-green-500"><BsFillPencilFill/></span> <p   className="underline text-green-600">Edit</p></div>
                    <div   className="flex flex-row items-center"> <span   className="text-red-500"><AiFillDelete/></span> <p   className="underline text-red-600">Delete</p></div>

                </div>
                </td>
              </tr>
            </tbody>
          </table>
<div   className="button mx-auto flex mt-16">
<Link to="/leaue" >
<button     className="lg:w-[10rem] btn-dc border border-green-700 mont-serif text-black mx-auto ">Go Back</button>
</Link>
</div>
              
        </div>
      </div>
    </>
  );
}
