import React,{useEffect} from 'react'

export default function Introdescription(props) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the component mounts
  }, []);
  return (
  
  <>
  
  <section   className=" body-font">
        <h1     className="lg:ml-[3rem] xs:ml-6 mt-6 text-2xl title-font mb-4 text-[#005125] tracking-tight text-left  mont-serif font-semibold flex flex-col">
          OUR Community
        </h1>

               <div   className="lg:w-[86%] lg:mx-auto">
             <p   className="mb-2 leading-relaxed mont-serif xs:p-[30px]">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus
              </p>
               </div>

        <div   className="lg:container lg:mx-auto flex flex-col justify-center items-center m-10">
          <img
              className="lg:w-[auto] h-[25rem] md:w-3/6 w-5/6 mb-10  object-center rounded"
            src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YnVpbGRpbmdzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
            alt=""
          />
          <div   className="lg:w-[86%] lg:mx-auto">
          <h1     className="lg:ml-[3rem] mt-6 text-2xl title-font mb-4 text-[#005125] tracking-tight text-left  mont-serif font-semibold flex flex-col">
          What We Offer
        </h1>
            <p   className="mb-2 leading-relaxed mont-serif">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              </p>

              <p   className="mt-2 leading-relaxed mont-serif">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              </p>

              <p   className=" mb-6 mt-2 leading-relaxed mont-serif">
              Horem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
              nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
              tellus elit sed risus. Maecenas eget condimentum velit, sit amet
              feugiat lectus. Class aptent taciti sociosqu ad litora torquent
              </p>

           
           
          </div>

<div   className="flex flex-col justify-center items-center gap-2">
<button     className="lg:w-[11rem] btn-ad mont-serif text-black ">Get in Touch</button>
<button     className="lg:w-[8rem] btn-dc border border-green-700 mont-serif text-black ">Go Back</button>
</div>

        </div>
      </section>
  </>
  )
}
