import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link, useNavigate } from "react-router-dom";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };
  const [value, onChange] = useState(new Date());

  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };
  const navigate = useNavigate();

  const handlelistener = () => {
    navigate(`/pendingapproval`);
  };
  return (
    <>
      <div   className="lg:w-[50%] lg:mx-auto mb-4">
        <form   className="lg:pt-[4rem]">
          <div   className="mb-4 ">
            <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
              htmlFor="name"
            >
              Event Name :
            </label>
            <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="name"
              type="text"
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <div   className="mb-4 lg:mt-[2rem]">
            <label
                className="block text-[#005125] font-bold mb-2 mont-serif"
              htmlFor="email"
            >
              Event Type :
            </label>
            <input
                className="mont-serif appearance-none border-b border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
              id="email"
              type="email"
              value={email}
              onChange={handleEmailChange}
            />
          </div>
          <div   className="mb-4 lg:mt-[4rem]">
            <label
                className="block text-gray-700 font-bold mb-2"
              htmlFor="message"
            >
              Message :
            </label>
            <textarea
                className="mont-serif appearance-none border border-[#007033] focus:border-indigo-500 w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none rounded-md"
              id="message"
              rows="8"
              value={message}
              onChange={handleMessageChange}
            />
          </div>
          <div   className="calender mx-auto">
            <Calendar
                className="mb-4 mx-auto w-full rounded-md mt-8"
              onChange={onChange}
              value={value}
            />
          </div>
          {/* <button
          className="bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="submit"
      >
        Submit
      </button> */}
          <div   className="flex flex-row items-center gap-2 justify-end mb-4 mt-6">
            <span   className="ml-3 font-medium text-[#005125]  mont-serif">
              All Day Event
            </span>
            <label   className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                  className="sr-only peer"
                checked={isChecked}
                onChange={handleToggle}
              />
              <div
                className={`w-11 h-6 bg-green-300 peer-focus:outline-none  ${
                  isChecked ? "peer-checked:bg-green-700" : ""
                } dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-green-200 ${
                  isChecked ? "peer-checked:after:translate-x-full" : ""
                } peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
              ></div>
            </label>
          </div>
          <hr   className=" border-b border-[#007033] w-full mb-8" />

          <h6   className="text-[#005125] mb-6 mont-serif">Timing :</h6>
          <div     className="flex flex-row justify-center items-center gap-[5rem] mb-4">
            <button
                  className="p-[12px] lg:w-[8rem] btn-dc border border-green-700 mont-serif text-black "
              style={{ padding: "12px" }}
            ></button>
            <p   className="text-[#005125]">to</p>
            <button
                  className="p-[12px] lg:w-[8rem] btn-dc border border-green-700 mont-serif text-black "
              style={{ padding: "12px" }}
            ></button>
          </div>

          <div     className="flex flex-col justify-center items-center gap-2 mt-[4rem] mb-4">
            <button onClick={handlelistener}     className="lg:w-[11rem] btn-ad mont-serif text-black ">
              Create Event
            </button>
            <button     className="lg:w-[8rem] btn-dc border border-green-700 mont-serif text-black ">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Form;
